<template>
    <div class="title-bar" :class="{ 'title-bar-fixed-top': isTitleBarFixed }">
        <div v-if="isTitleBarFixed && rowIndex === 2" class="menu-tab-btn">
            <n-popover
                :show="showMenuPopover"
                placement="bottom-start"
                :width="140"
                :show-arrow="false"
                @clickoutside="_handleCloseMenuPopover"
                to=".title-bar"
            >
                <template #trigger>
                    <n-icon :size="32" @click="_handleShowMenuPopover">
                        <Menu />
                    </n-icon>
                </template>
                <div class="menu-list-container">
                    <div class="menu-list-wrap">
                        <div
                            v-for="(item, idx) in MenuTabsList"
                            :key="idx"
                            class="list-item-control"
                            @click="_handleChangeTabPage(item.name)"
                        >
                            <span>{{ item.title }}</span>
                        </div>
                    </div>
                </div>
            </n-popover>
        </div>

        <div
            :class="{
                'logo-cont animation-in': !isTitleBarFixed,
                'logo-cont-fixed-top animation-out': isTitleBarFixed,
            }"
            @click="_handleBackHome"
        >
            <img src="@/assets/images/layout/app_logo.png" />
            <div>Charm</div>
        </div>

        <div
            v-if="!(isTitleBarFixed && rowIndex === 2)"
            class="tab-menu"
            :class="{ 'tab-fixed-top': isTitleBarFixed }"
        >
            <n-tabs type="bar" default-value="charmIndex" :value="tabsValue">
                <n-tab
                    v-for="(item, idx) in MenuTabsList"
                    :key="idx"
                    :name="item.name"
                    @click="_handleChangeTabPage(item.name)"
                >
                    {{ item.title }}
                </n-tab>
            </n-tabs>
        </div>

        <div v-if="isTitleBarFixed" class="user-login">
            <div
                v-if="!isLogined"
                class="login-sec-container login-undo-container"
                @click="showLoginAndRegisterModal"
            >
                <img src="@/assets/images/layout/user_login.png" />
                <div v-if="rowIndex === 4">Log in/sign up</div>
            </div>
            <div v-else class="login-sec-container login-done-container">
                <n-popover
                    :show="showPopover"
                    class="custom-popover"
                    placement="bottom-end"
                    trigger="manual"
                    :width="160"
                    :show-arrow="false"
                    @clickoutside="_handleCloseUserPopover"
                    to=".login-done-container"
                >
                    <template #trigger>
                        <div
                            class="login-done-trigger"
                            @click="_handleShowUserPopover"
                        >
                            <img
                                v-if="userInfo?.image"
                                :src="userInfo?.image"
                            />
                            <img
                                v-else
                                src="@/assets/images/layout/user_login.png"
                            />
                            <div class="user-name">
                                {{ userInfo?.nickname }}
                            </div>
                        </div>
                    </template>
                    <div class="login-done-list">
                        <div class="login-done-list-wrap">
                            <div class="list-item-control ellipsis">
                                <span class="ellipsis"
                                    >Hello, {{ userInfo?.nickname }}</span
                                >
                            </div>
                            <div
                                class="list-item-control"
                                @click="_handleGoToMyProfile"
                            >
                                <span>Your Profile</span>
                            </div>
                            <!-- <div class="list-item-control">
                                <span>Inbox</span>
                            </div> -->
                            <div
                                class="list-item-control line-top"
                                @click="_handleLoginOut"
                            >
                                <span>Log Out</span>
                            </div>
                        </div>
                    </div>
                </n-popover>
            </div>
        </div>

        <!-- 登陆注册弹框 -->
        <n-modal
            v-model:show="isShowLoginAndRegisterModal"
            :mask-closable="false"
            to=".title-bar"
        >
            <LoginAndRegister
                @closeLoginAndRegisterModal="closeLoginAndRegisterModal"
            ></LoginAndRegister>
        </n-modal>

        <!-- 医生认证资料填写弹框 -->
        <n-modal
            v-model:show="isShowDoctorAuthenticationModal"
            :mask-closable="false"
            to=".title-bar"
        >
            <DoctorAuthentication
                @closeDoctorAuthenticationModal="closeDoctorAuthenticationModal"
            ></DoctorAuthentication>
        </n-modal>

        <!--delete prompt modal-->
        <PromptModal
            v-model:show="showConfirmLoginOutModal"
            :options="{
                showClose: false,
                title: 'Are you sure you want to log out?',
                confirmBtnText: 'Sign Out',
            }"
            @on-confirm="_onConfirmLoginOut"
        ></PromptModal>
    </div>
</template>

<script lang="ts" setup>
import { NTabs, NTab, NModal, NPopover, NIcon, NMenu } from "naive-ui";
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { Menu } from "@vicons/ionicons5";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import LoginAndRegister from "./LoginAndRegister.vue";
import DoctorAuthentication from "./DoctorAuthentication.vue";
import PromptModal from "@/components/PromptModal.vue";
import { useCalCScreenMinWidth } from "@/composables/useCalcScreenMinWidth";
import { MenuTabsList } from "@/constant";

const props = defineProps({
    isHome: {
        type: Boolean,
        default: () => {
            return false;
        },
    },
});

const router = useRouter();
const { screenWinWidth } = useCalCScreenMinWidth();
const store = useStore();

const tabsValue = ref<string>("Home");
const isSrollTopDis = ref<boolean>(false);
const showMenuPopover = ref<boolean>(false);
const showPopover = ref<boolean>(false);
const isShowLoginAndRegisterModal = ref<boolean>(false);
const isShowDoctorAuthenticationModal = ref<boolean>(false);
const showConfirmLoginOutModal = ref<boolean>(false);
const isLogined = computed(() => {
    return store.getters["user/getLoginStatus"];
});
const showLoginModal = computed(() => {
    return store.getters["user/getShowLoginModal"];
});
const userInfo = computed(() => {
    return store.getters["user/getUserInfo"];
});
const rowIndex = computed(() => {
    return screenWinWidth.value <= 744
        ? 2
        : screenWinWidth.value <= 1024
        ? 3
        : 4;
});

const logoContOpacityStyle = ref<any>({
    display: "flex",
    opacity: 1,
});

const logoContFixedTopOpacityStyle = ref<any>({
    display: "none",
});

const isTitleBarFixed = computed(() => {
    return isSrollTopDis.value || !props.isHome;
});

watch(
    () => router.currentRoute.value,
    (to) => {
        const curMetaLevel = to.meta?.level;
        if (to.name === "Home" && curMetaLevel === 1) {
            tabsValue.value = "Home";
        } else if (
            (to.path.includes("/doctor") && curMetaLevel === 1) ||
            (to.path.includes("/doctor/") && curMetaLevel === 2)
        ) {
            tabsValue.value = "Doctors";
        } else if (
            (to.path.includes("/post") && curMetaLevel === 1) ||
            (to.path.includes("/post/") && curMetaLevel === 2)
        ) {
            tabsValue.value = "Posts";
        } else if (
            (to.path.includes("/procedure") && curMetaLevel === 1) ||
            (to.path.includes("/procedure/") && curMetaLevel === 2)
        ) {
            tabsValue.value = "Procedures";
        } else if (
            (to.path.includes("/instrument") && curMetaLevel === 1) ||
            (to.path.includes("/instrument/") && curMetaLevel === 2)
        ) {
            tabsValue.value = "Instruments";
        } else {
            tabsValue.value = "";
        }
    },
    { immediate: true }
);

watch(
    () => showLoginModal.value,
    (n: boolean) => {
        isShowLoginAndRegisterModal.value = n;
    }
);

// 切换tab
const _handleChangeTabPage = (value: string | number) => {
    // console.log(value);
    switch (value) {
        case "Home":
            router.push({ path: "/" });
            tabsValue.value = "Home";
            break;
        case "Doctors":
            router.push({ path: "/doctor" });
            tabsValue.value = "Doctors";
            break;
        case "Posts":
            router.push({ path: "/post" });
            tabsValue.value = "Posts";
            break;
        case "Procedures":
            router.push({ path: "/procedure" });
            tabsValue.value = "Procedures";
            break;
        case "Instruments":
            router.push({ path: "/instrument" });
            tabsValue.value = "Instruments";
            break;
    }
    nextTick(() => {
        _handleCloseMenuPopover();
    });
    //console.log(tabsValue);
};

// 展示登陆注册弹框
const showLoginAndRegisterModal = () => {
    isShowLoginAndRegisterModal.value = true;
};

// 关闭登陆注册弹框
const closeLoginAndRegisterModal = () => {
    isShowLoginAndRegisterModal.value = false;
};

// 关闭医生认证资料填写弹框
const closeDoctorAuthenticationModal = () => {
    isShowDoctorAuthenticationModal.value = false;
};

const _handleBackHome = () => {
    if (router.currentRoute.value.name === "Home") {
        return;
    }
    router.push({ path: "/" });
};

const _handleCloseMenuPopover = () => {
    showMenuPopover.value = false;
};

const _handleShowMenuPopover = () => {
    showMenuPopover.value = true;
};

const _handleCloseUserPopover = () => {
    showPopover.value = false;
};

const _handleShowUserPopover = () => {
    showPopover.value = true;
};

const _handleShowConfirmLoginOutModal = () => {
    showConfirmLoginOutModal.value = !showConfirmLoginOutModal.value;
};

const _handleLoginOut = () => {
    _handleShowConfirmLoginOutModal();
    nextTick(() => {
        _handleCloseUserPopover();
    });
};

const _onConfirmLoginOut = () => {
    store.dispatch("user/userLoginOut");
    nextTick(() => {
        router.push({ path: "/" });
    });
};

const _goToMyProfilePage = () => {
    nextTick(() => {
        router.push({
            path: "/user/detail",
            query: { memberId: userInfo.value?.userId },
        });
    });
};

const _handleGoToMyProfile = () => {
    _goToMyProfilePage();
    nextTick(() => {
        _handleCloseUserPopover();
    });
};

onMounted(() => {
    window.addEventListener("scroll", () => {
        const scrollTop =
            document.documentElement.scrollTop || document.body.scrollTop;
        // console.log("scrollTop===", scrollTop);

        logoContOpacityStyle.value.opacity =
            1 - scrollTop / 145 < 0 ? 0 : 1 - scrollTop / 145;

        if (logoContOpacityStyle.value.opacity === 0) {
            logoContOpacityStyle.value.display = "none";
        } else {
            logoContOpacityStyle.value.display = "flex";
        }

        if (scrollTop >= 145) {
            isSrollTopDis.value = true;
            logoContFixedTopOpacityStyle.value.display = "flex";
        } else {
            isSrollTopDis.value = false;
            logoContFixedTopOpacityStyle.value.display = "none";
        }
    });
});
</script>

<style lang="scss" scoped>
.title-bar {
    position: relative;
    width: 100%;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    background-color: $global-bg-color;
    z-index: 999;

    &.title-bar-fixed-top {
        position: fixed;
        left: 0;
        top: 0;
        height: 60px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        background-color: #fff;
    }

    .logo-cont {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        img {
            width: 38px;
            height: 40px;
        }

        div {
            margin-top: 10px;
            color: #000;
            font-family: "Oleo Script Swash Caps Regular";
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    .logo-cont-fixed-top {
        position: absolute;
        left: 50px;
        bottom: 10px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;

        &.animation-out {
            animation: animation-out 0.5s;
        }

        &.animation-in {
            animation: animation-in 0.5s;
        }

        img {
            width: 38px;
            height: 40px;
        }

        div {
            margin-left: 10px;
            color: #000;
            font-family: "Oleo Script Swash Caps Regular";
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    @keyframes animation-out {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @keyframes animation-in {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

    .tab-menu {
        margin-top: 22px;

        &.tab-fixed-top {
            margin-top: 0;
            position: absolute;
            left: 50%;
            top: 15px;
            transform: translateX(-50%);
        }

        :deep(.n-tabs-tab) {
            color: #342b2b;
            text-align: center;
            font-family: Philosopher Regular;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    .user-login {
        position: absolute;
        right: 50px;
        bottom: 14px;

        .login-sec-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .login-done-trigger {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                > img {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    object-fit: fill;
                    overflow: hidden;
                }

                .user-name {
                    display: block;
                    margin-left: 8px;
                    color: #504c4c;
                    font-family: "Open Sans";
                    font-size: 16px;
                    font-weight: 600;
                    cursor: pointer;
                }
            }

            :deep(.custom-popover) {
                margin-top: 16px;
                padding: 0;
            }
            .login-done-list {
                .login-done-list-wrap {
                    .list-item-control {
                        padding: 12px 0;
                        cursor: pointer;

                        span {
                            display: inline-block;
                            width: 100%;
                            padding: 0 20px;
                            color: #000;
                            font-family: "PingFang SC";
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 20px; /* 125% */
                        }
                    }

                    .list-item-control:hover {
                        background-color: #dde7ff;
                        span {
                            color: #2a8cff;
                        }
                    }

                    .line-top {
                        border-top: 1px solid rgba(0, 0, 0, 0.1);
                    }
                }
            }
        }
    }

    .menu-list-container {
        .menu-list-wrap {
            .list-item-control {
                padding: 12px 0;
                cursor: pointer;

                span {
                    display: inline-block;
                    width: 100%;
                    padding: 0 10px;
                    color: #000;
                    font-family: "PingFang SC";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px; /* 125% */
                }
            }

            .list-item-control:hover {
                background-color: #dde7ff;
                span {
                    color: #2a8cff;
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) and (min-width: 744px) {
    .title-bar-fixed-top {
        display: flex;
        padding: 0 40px;
        flex-direction: row;
        justify-content: space-between;
        .menu-tab-btn {
            display: flex;
            height: 100%;
            align-items: center;
        }
        .logo-cont-fixed-top {
            position: static;
            > img {
                margin: 0 auto;
            }
        }
        .user-login {
            position: static;
            min-width: 32px;
        }
    }
}

@media screen and (max-width: 744px) {
    .tab-menu {
        :deep(.n-tabs .n-tabs-tab-pad) {
            width: 16px;
        }
    }
    .title-bar-fixed-top {
        display: flex;
        padding: 0 12px;
        flex-direction: row;
        justify-content: space-between;
        .menu-tab-btn {
            display: flex;
            height: 100%;
            align-items: center;
        }
        .logo-cont-fixed-top {
            position: static;
            > img {
                margin: 0 auto;
            }
        }
        .user-login {
            position: static;
            min-width: 32px;
        }
        .user-login {
            .login-sec-container {
                .login-done-trigger {
                    .user-name {
                        display: none;
                    }
                }
            }
        }
    }
}
</style>
