/**
 * calcScreenMinWidth
 */
import { ref, onUnmounted, onMounted, computed } from "vue";

export const useCalCScreenMinWidth = () => {
    const win = window as any;
    const screenMinWidth = ref<number>(1440);
    const screenWinWidth = ref<number>(win.innerWidth);

    const screenRowIndex = computed(() => {
        return screenWinWidth.value <= 744
            ? 2
            : screenWinWidth.value <= 1024
            ? 3
            : 4;
    });

    // 计算屏幕大小
    const calcScreenMinWidth = (vWidth: number) => {
        screenWinWidth.value = vWidth;
        if (vWidth >= 1440) {
            screenMinWidth.value = 1440;
        }
        if (1024 <= vWidth && vWidth < 1440) {
            screenMinWidth.value = 1024;
        }
        if (vWidth < 1020) {
            screenMinWidth.value = 744;
        }
    };

    onMounted(() => {
        calcScreenMinWidth(win.innerWidth);

        win.addEventListener("resize", () => {
            calcScreenMinWidth(win.innerWidth);
        });
    });

    onUnmounted(() => {
        win.removeEventListener("resize", () => {
            calcScreenMinWidth(win.innerWidth);
        });
    });

    return {
        screenMinWidth,
        screenWinWidth,
        screenRowIndex,
        calcScreenMinWidth,
    };
};
