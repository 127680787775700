import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@/assets/icons/icon_video_preview_close.svg'
import _imports_1 from '@/assets/images/doctor/video_thumb_img.png'
import _imports_2 from '@/assets/icons/icon_video_preview_more.svg'


const _hoisted_1 = { class: "preview-container" }
const _hoisted_2 = { class: "preview-header-contianer" }
const _hoisted_3 = { class: "preview-mainer-container" }
const _hoisted_4 = { class: "video-container" }
const _hoisted_5 = {
  key: 0,
  class: "video-control"
}
const _hoisted_6 = {
  key: 1,
  class: "thumb-img",
  src: _imports_1,
  alt: "video_thumb_img"
}
const _hoisted_7 = { class: "overlay-container" }
const _hoisted_8 = { class: "more-container" }

import {
    computed,
    nextTick,
    onMounted,
    reactive,
    ref,
    shallowRef,
    watch,
} from "vue";
import { NModal, NPopover, NIcon } from "naive-ui";
import { PlayCircleOutline, PauseCircleOutline } from "@vicons/ionicons5";
import { VideoPlayer } from "vue-video-player";
import videojs from "video.js";
import "video.js/dist/video-js.css";

type VideoJsPlayer = ReturnType<typeof videojs>;


export default /*@__PURE__*/_defineComponent({
  __name: 'VideoPreviewModal',
  props: {
    show: {
        type: Boolean,
        default: () => {
            return false;
        },
    },
    to: {
        type: [String, HTMLElement],
        default: () => {
            return "body";
        },
    },
    options: {
        type: Object,
        default: () => {
            return {
                showEdit: false,
                src: "",
            };
        },
    },
},
  emits: [
    "update:show",
    "onCloseModal",
    "onConfirmModal",
    "onEditVideo",
    "onDeleteVideo",
],
  setup(__props, { emit: __emit }) {

const props = __props;

const emits = __emit;

const videoRef = ref();
const videoPlayer = shallowRef<VideoJsPlayer>();
const showModal = ref<boolean>(false);
const resourcesSrc = computed(() => {
    return props.options?.src;
});
const videoPaused = ref<boolean | undefined>(true);
const showEditFlag = computed(() => {
    return props.options?.showEdit && videoPaused.value;
});
watch(
    () => props.show,
    (newValue: boolean) => {
        showModal.value = newValue;
        if (newValue) {
            videoPaused.value = true;
        }
    },
    { immediate: true }
);

const handleMounted = (payload: any) => {
    videoPlayer.value = payload.player;
    //console.log("Basic player mounted", payload);
};

const handleEvent = (log: any) => {
    //console.log("Basic player event", log);
    if (log?.type === "pause") {
        videoPaused.value = true;
    }
};

// 显示退出提醒弹框
const handleShowModal = () => {
    //showModal.value = true;
    emits("update:show", true);
};

// 关闭退出提醒弹框
const onCloseModel = () => {
    //showModal.value = false;
    emits("update:show", false);
};

const onConfirm = () => {
    emits("onConfirmModal");
    nextTick(() => {
        onCloseModel();
    });
};

const onCancel = () => {
    emits("onCloseModal");
    nextTick(() => {
        onCloseModel();
    });
};

const _handleEditVideo = () => {
    emits("onEditVideo");
};

const _handleDeleteVideo = () => {
    emits("onDeleteVideo");
};

const _handleVideoPlay = () => {
    videoPaused.value = !videoPaused.value;
    if (videoPaused.value) {
        videoPlayer.value?.pause();
    } else {
        videoPlayer.value?.play();
    }
};

const _InitVideoPlayer = () => {
    videoPaused.value = true;
};

onMounted(() => {
    _InitVideoPlayer();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(NModal), {
    show: showModal.value,
    "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => ((showModal).value = $event)),
    "transform-origin": "center",
    "mask-closable": false,
    "auto-focus": false,
    to: __props.to
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "title" }, [
            _createElementVNode("h2")
          ], -1)),
          _createVNode(_unref(NIcon), {
            class: "btn-icon",
            size: "34",
            onClick: onCancel
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createElementVNode("img", { src: _imports_0 }, null, -1)
            ])),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (resourcesSrc.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_unref(VideoPlayer), {
                    class: "video-player",
                    src: resourcesSrc.value,
                    playsinline: "",
                    controls: "",
                    volume: 0.6,
                    "playback-rates": [0.7, 1.0, 1.5, 2.0],
                    height: 670,
                    width: 960,
                    autoplay: false,
                    reportTouchActivity: "",
                    onMounted: handleMounted,
                    onPause: _cache[0] || (_cache[0] = ($event: any) => (handleEvent($event)))
                  }, null, 8, ["src"])
                ]))
              : (_openBlock(), _createElementBlock("img", _hoisted_6))
          ]),
          _withDirectives(_createElementVNode("div", _hoisted_7, [
            _createVNode(_unref(NIcon), {
              class: "btn-icon play-icon",
              color: "#ffffff",
              onClick: _handleVideoPlay
            }, {
              default: _withCtx(() => [
                (videoPaused.value)
                  ? (_openBlock(), _createBlock(_unref(PlayCircleOutline), { key: 0 }))
                  : (_openBlock(), _createBlock(_unref(PauseCircleOutline), { key: 1 }))
              ]),
              _: 1
            }),
            _withDirectives(_createElementVNode("div", _hoisted_8, [
              _createVNode(_unref(NPopover), {
                class: "custom-popover",
                placement: "bottom-end",
                trigger: "click",
                "show-arrow": false,
                to: ".more-container"
              }, {
                trigger: _withCtx(() => [
                  _createVNode(_unref(NIcon), { class: "btn-icon" }, {
                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createElementVNode("img", { src: _imports_2 }, null, -1)
                    ])),
                    _: 1
                  })
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", { class: "action-container" }, [
                    _createElementVNode("div", { class: "action-list" }, [
                      _createElementVNode("div", {
                        class: "action-item",
                        onClick: _handleEditVideo
                      }, _cache[5] || (_cache[5] = [
                        _createElementVNode("span", { class: "text" }, "Edit video", -1)
                      ])),
                      _createElementVNode("div", {
                        class: "action-item",
                        onClick: _handleDeleteVideo
                      }, _cache[6] || (_cache[6] = [
                        _createElementVNode("span", { class: "text" }, "Delete video", -1)
                      ]))
                    ])
                  ])
                ]),
                _: 1
              })
            ], 512), [
              [_vShow, showEditFlag.value]
            ])
          ], 512), [
            [_vShow, videoPaused.value]
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["show", "to"]))
}
}

})