<template>
    <div class="doctor-authentication-cont">
        <div class="doctor-authentication-title">Set Up Your Profile</div>

        <!-- 步骤条 -->
        <div class="step-bar-cont">
            <div class="number-cont">
                <div v-if="setProFilesStep === 1" class="number current-step">1</div>
                <img v-if="setProFilesStep === 2" src="@/assets/images/layout/step_done_icon.png" />

                <div class="line" :class="{ 'current-step': setProFilesStep === 2 }"></div>

                <div class="number" :class="{ 'current-step': setProFilesStep === 2 }">2</div>
            </div>

            <div class="text-cont">
                <div class="current-step">Enter Your Profiles</div>
                <div :class="{ 'current-step': setProFilesStep === 2 }">Verify your identity</div>
            </div>
        </div>

        <!-- 填写第一部分资料 -->
        <div v-if="setProFilesStep === 1" class="part-one-profile-input">
            <div class="title">Personal Information</div>

            <n-input 
                v-model:value="personalMessageModel.email"
                placeholder="Enter your Email Address"
                size="large"
                @keydown.enter.prevent
            />

            <n-input 
                v-model:value="personalMessageModel.email"
                placeholder="Enter your Email Address"
                size="large"
                @keydown.enter.prevent 
            />

            <n-input 
                v-model:value="personalMessageModel.email"
                placeholder="Enter your Email Address"
                size="large"
                @keydown.enter.prevent 
            />

            <div class="title">Practice Information</div>

            <n-input 
                v-model:value="personalMessageModel.email"
                placeholder="Enter your Email Address"
                size="large"
                @keydown.enter.prevent 
            />

            <n-input 
                v-model:value="personalMessageModel.email"
                placeholder="Enter your Email Address"
                size="large"
                @keydown.enter.prevent 
            />
        </div>

        <!-- 填写第二部分资料 -->
        <div v-if="setProFilesStep === 2" class="part-two-profile-input">

        </div>

        <!-- 关闭按钮 -->
        <div class="close-cont" @click="closeDoctorAuthenticationModal">
            <img src="@/assets/images/layout/close_icon.png" />
        </div>
    </div>
</template>

<script lang="ts" setup>
import { NInput } from 'naive-ui';
import { ref } from 'vue';

const emit = defineEmits(['closeDoctorAuthenticationModal']);

const setProFilesStep = ref<number>(1);

const personalMessageModel = ref<any>({
    email: null,
    practice: null,
})

// 关闭弹窗
const closeDoctorAuthenticationModal = () => {
    emit('closeDoctorAuthenticationModal');
}

</script>

<style lang="scss" scoped>
    .doctor-authentication-cont {
        position: relative;
        width: 1280px;
        height: 927px;
        padding: 80px 70px 0;
        box-sizing: border-box;
        background-color: #fff;

        .doctor-authentication-title {
            color: #000;
            text-align: center;
            font-family: "Open Sans";
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        .step-bar-cont {
            margin-top: 46px;
            color: rgba(0, 0, 0, 0.2);
            text-align: center;
            font-family: "PingFang SC";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;

            .number-cont {
                padding: 0 188px;
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                .number {
                    width: 28px;
                    height: 28px;
                    border-radius: 50%;
                    border: 2px solid rgba(0, 0, 0, 0.2);
                    box-sizing: border-box;
                    background-color: #fff;
                    line-height: 26px;

                    &.current-step {
                        color: #000;
                        border: 2px solid #000;
                    }
                }

                img {
                    width: 28px;
                    height: 28px;
                }

                .line {
                    width: 480px;
                    height: 2px;
                    background-color: rgba(0, 0, 0, 0.2);

                    &.current-step {
                        background-color: #000;
                    }
                }
            }

            .text-cont {
                margin-top: 6px;
                padding: 0 145px;
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                div.current-step {
                    color: #000;
                }
            }
        }

        .part-one-profile-input {
            margin-top: 46px;

            .title {
                margin-top: 20px;
                color: rgba(0, 0, 0, 0.6);
                font-family: "Open Sans";
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }

            :deep(.n-input) {
                margin-top: 16px;
            }
        }

        .close-cont {
            position: absolute;
            top: 30px;
            right: 30px;
            cursor: pointer;

            img {
                width: 20px;
                height: 20px;
            }
        }
    }
</style>