<template>
    <n-config-provider :theme-overrides="themeOverrides">
        <TitleBar :is-home="curRouterIsHome"></TitleBar>

        <router-view :key="routerKey" />

        <FooterBar></FooterBar>

        <!--message alert-->
        <n-message-provider
            placement="bottom"
            container-class="custom-message-dialog"
        >
            <MessageContent></MessageContent>
        </n-message-provider>
    </n-config-provider>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import {
    NConfigProvider,
    GlobalThemeOverrides,
    NMessageProvider,
} from "naive-ui";
import MessageContent from "@/views/layout/MessageContent.vue";
import TitleBar from "@/views/layout/TitleBar.vue";
import FooterBar from "@/views/layout/FooterBar.vue";
import { useStore } from "vuex";
import { MessageAlert } from "./composables/useMessageAlert";

const router = useRouter();
const store = useStore();
const curRouterIsHome = ref<boolean>(false);
const themeOverrides: GlobalThemeOverrides = {
    common: {
        primaryColor: "#1195F5",
    },
    Button: {
        textColor: "#000000",
    },
    Input: {
        borderHover: "1px solid rgb(224, 224, 230)",
        borderFocus: "1px solid rgb(224, 224, 230)",
        boxShadowFocus: "1px solid rgb(224, 224, 230)",
    },
    Select: {
        peers: {
            InternalSelection: {
                borderHover: "1px solid rgb(224, 224, 230)",
                borderActive: "1px solid rgb(224, 224, 230)",
                borderFocus: "1px solid rgb(224, 224, 230)",
                boxShadowHover: "1px solid rgb(224, 224, 230)",
                boxShadowActive: "1px solid rgb(224, 224, 230)",
                boxShadowFocus: "1px solid rgb(224, 224, 230)",
            },
        },
    },
};

const routerKey = computed(() => {
    return router.currentRoute.value?.fullPath;
});

watch(
    () => router.currentRoute.value,
    (newValue) => {
        //console.log("router.currentRoute===>", newValue, oldValue);
        if (newValue.name === "Home" && newValue.matched.length > 0) {
            curRouterIsHome.value = true;
        } else {
            curRouterIsHome.value = false;
        }
    },
    { immediate: true }
);

const _fetchUserInfoByApi = async () => {
    try {
        await store.dispatch("user/fetchUserInfo");
    } catch (error: any) {
        const errMess = error?.message || "login failed";
        MessageAlert(errMess);
    }
};

onMounted(() => {
    //console.log("reloadApp====>")
    nextTick(() => {
        _fetchUserInfoByApi();
    });
});
</script>

<style lang="scss" scoped></style>
