/**
 * tool function
 */

/**
 * validate email
 * @param email
 * @returns
 */
export function validateEmail(email: string) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(email);
}

/**
 * validate password
 * @param password
 * @returns
 */
export function validatePassword(password: string) {
    const passRegex = /^(?=.*[A-Za-z])(?=.*[0-9])[A-Za-z0-9]{6,16}$/;
    return passRegex.test(password);
}

/**
 * validate login password
 * @param name
 * @returns
 */
export function validateLoginPassword(name: string) {
    const regex = /^[a-zA-Z0-9_-]{6,30}$/;
    return regex.test(name);
}

/**
 * validate userName
 * @param password
 * @returns
 */
export function validateUserName(name: string) {
    const regex = /^[a-zA-Z0-9_-]{5,30}$/;
    return regex.test(name);
}

/**
 * 特殊字符转换
 * @param input
 * @returns
 */
export function convertUnicode(input: string) {
    return input.replace(/\\+u([0-9a-fA-F]{4})/g, (a, b) =>
        String.fromCharCode(parseInt(b, 16))
    );
}

/**
 * url截取文件名已文件后缀
 * @param urlPath
 * @returns
 */
export function getFilenameFromUrl(urlPath: string) {
    if (!urlPath) {
        return null;
    }
    const url: any = new URL(urlPath);
    const pathname: string = url.pathname;
    const result = pathname.match(/.*\/(.+)$/);
    return result ? result[1] : null;
}
