import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/layout/member_career_icon.png'
import _imports_1 from '@/assets/images/layout/doctor_career_icon.png'
import _imports_2 from '@/assets/images/layout/next_icon.png'


const _hoisted_1 = { class: "choose-user-identity" }
const _hoisted_2 = { class: "user-identity-list" }
const _hoisted_3 = { class: "next-btn-cont" }

import { NButton } from "naive-ui";
import { ref, computed, watch } from "vue";
import { MessageAlert } from "@/composables/useMessageAlert";
import { UserRole } from "@/constant";


export default /*@__PURE__*/_defineComponent({
  __name: 'ChoseUserIdentify',
  props: {
    curRoleType: {
        type: String,
        default: () => {
            return "";
        },
    },
},
  emits: ["onNext"],
  setup(__props, { emit: __emit }) {

const props = __props;
const emits = __emit;

const curChooseCareerSelected = ref<number>(0);
const isDisabledChooseCareerSelected = computed(() => {
    return curChooseCareerSelected.value ? false : true;
});

watch(
    () => props.curRoleType,
    (newV) => {
        curChooseCareerSelected.value =
            newV === UserRole.User.toUpperCase()
                ? 1
                : newV === UserRole.Doctor.toUpperCase()
                ? 2
                : 0;
    },
    { immediate: true }
);

const _hdanleChooseCareerSelected = (type: number) => {
    curChooseCareerSelected.value = type;
};

// 注册-选择完职业之后点击next按钮
const _handleChooseCareerNext = () => {
    if (isDisabledChooseCareerSelected.value) {
        MessageAlert("Please choose a User identity");
        return;
    }
    emits("onNext", curChooseCareerSelected.value);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["user-identity-item", { selected: curChooseCareerSelected.value === 1 }]),
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_hdanleChooseCareerSelected(1)), ["stop"]))
      }, _cache[2] || (_cache[2] = [
        _createElementVNode("div", { class: "user-identity-item-img-cont" }, [
          _createElementVNode("img", { src: _imports_0 })
        ], -1),
        _createElementVNode("div", { class: "user-identity-item-name" }, "Member", -1)
      ]), 2),
      _createElementVNode("div", {
        class: _normalizeClass(["user-identity-item", { selected: curChooseCareerSelected.value === 2 }]),
        onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_hdanleChooseCareerSelected(2)), ["stop"]))
      }, _cache[3] || (_cache[3] = [
        _createElementVNode("div", { class: "user-identity-item-img-cont" }, [
          _createElementVNode("img", { src: _imports_1 })
        ], -1),
        _createElementVNode("div", { class: "user-identity-item-name" }, "Doctor", -1)
      ]), 2)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_unref(NButton), {
        color: 
                    !isDisabledChooseCareerSelected.value
                        ? '#000000'
                        : 'rgba(0, 0, 0, 0.2)'
                ,
        "text-color": 
                    !isDisabledChooseCareerSelected.value ? '#88EEFF' : '#ffffff'
                ,
        "icon-placement": "right",
        disabled: isDisabledChooseCareerSelected.value,
        onClick: _handleChooseCareerNext
      }, {
        icon: _withCtx(() => _cache[4] || (_cache[4] = [
          _createElementVNode("img", {
            style: {"margin-left":"12px","width":"26px","height":"14px"},
            src: _imports_2
          }, null, -1)
        ])),
        default: _withCtx(() => [
          _cache[5] || (_cache[5] = _createTextVNode(" Next "))
        ]),
        _: 1
      }, 8, ["color", "text-color", "disabled"])
    ])
  ]))
}
}

})