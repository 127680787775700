/**
 * useRegisterAct
 */

import api from "@/api";
import { MessageAlert } from "./useMessageAlert";
import { UserRole } from "@/constant";

export const useRegisterAct = () => {
    /**
     * 验证邮箱
     * @param param
     * @returns
     */
    const _clickVerifyEmailByApi = async (param: {
        email: string;
        userRole: UserRole;
    }) => {
        try {
            const res: any = await api.clickVerification(param);
            return Promise.resolve(res);
        } catch (err: any) {
            const errMess = err?.message || "email verify failed";
            MessageAlert(errMess);
        }
    };

    /**
     * isConfirmEmail
     * @param param
     * @returns
     */
    const _isConfirmEmailByApi = async (param: {
        email: string;
        userRole: UserRole;
    }) => {
        try {
            const res: any = await api.isConfirmEmail(param);
            return Promise.resolve(res);
        } catch (err: any) {
            const errMess = err?.message || "confirm email failed";
            MessageAlert(errMess);
        }
    };

    /**
     * email register
     * @param param
     * @returns
     */
    const _registerEmailByApi = async (param: {
        email: string;
        nickname: string;
        password: string;
        userRole: UserRole;
    }) => {
        try {
            const res: any = await api.registerEmail(param);
            return Promise.resolve(res);
        } catch (err: any) {
            const errMess = err?.message || "email register failed";
            MessageAlert(errMess);
        }
    };

    /**
     * fetch all procudure
     * @param param
     * @returns
     */
    const _findAllProcedureByApi = async (param?: any) => {
        try {
            const res: any = await api.findAllProcedure(param);
            return Promise.resolve(res);
        } catch (err: any) {
            const errMess = err?.message || "fetch all procudure failed";
            MessageAlert(errMess);
        }
    };

    /**
     * set profile
     * @param param
     * @returns
     */
    const _userSetTheirProfileByApi = async (param: {
        email?: string;
        nickname?: string;
        birthday?: string;
        gender?: number;
        interested?: any[];
        mobile?: string;
        bio?: string;
    }) => {
        try {
            const res: any = await api.userSetTheirProfile(param);
            return Promise.resolve(res);
        } catch (err: any) {
            const errMess = err?.message || "set profile failed";
            MessageAlert(errMess);
        }
    };

    /**
     * fetch user info
     * @param param
     * @returns
     */
    const _FetchUserEditProfileByApi = async (param?: any) => {
        try {
            const res: any = await api.getUserEditProfileData(param);
            return Promise.resolve(res);
        } catch (err: any) {
            const errMess = err?.message || "fetch user info failed";
            MessageAlert(errMess);
        }
    };

    const _resetPasswordByApi = async (param: {
        email: any;
        newPassword: any;
        confirmPassword: any;
    }) => {
        const params = {
            email: param.email,
            newPassword: param.newPassword,
            confirmPassword: param.confirmPassword,
        };
        try {
            const res: any = await api.resetPassword(params);
            return Promise.resolve(res);
        } catch (error: any) {
            const errMess = error?.message || "reset password failed";
            MessageAlert(errMess);
        }
    };

    const _verifyEmailByApi = async (param: { token: string }) => {
        try {
            const res: any = await api.verifyEmail(param);
            return Promise.resolve(res);
        } catch (err: any) {
            const errMess = err?.message || "verify email failed";
            MessageAlert(errMess);
        }
    };

    return {
        _clickVerifyEmailByApi,
        _isConfirmEmailByApi,
        _registerEmailByApi,
        _findAllProcedureByApi,
        _userSetTheirProfileByApi,
        _FetchUserEditProfileByApi,
        _resetPasswordByApi,
        _verifyEmailByApi,
    };
};
