import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/icons/icon_close.svg'


const _hoisted_1 = { class: "prompt-model-container" }
const _hoisted_2 = {
  key: 0,
  class: "model-header-contianer"
}
const _hoisted_3 = { class: "title" }
const _hoisted_4 = {
  key: 1,
  class: "model-mainer-container"
}
const _hoisted_5 = { class: "content-cont" }
const _hoisted_6 = { class: "model-footer-container" }
const _hoisted_7 = { class: "footer-btn-control" }
const _hoisted_8 = { class: "btn-group-control" }
const _hoisted_9 = { class: "btn-text" }
const _hoisted_10 = { class: "btn-text" }

import { computed, nextTick, ref, watch } from "vue";
import { NButton, NModal, NIcon } from "naive-ui";


export default /*@__PURE__*/_defineComponent({
  __name: 'PromptModal',
  props: {
    show: {
        type: Boolean,
        default: () => {
            return false;
        },
    },
    to: {
        type: [String, HTMLElement],
        default: () => {
            return "body";
        },
    },
    options: {
        type: Object,
        default: () => {
            return null;
        },
    },
},
  emits: ["update:show", "onCalcel", "onConfirm"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emits = __emit;

const showModal = ref<boolean>(false);

const title = computed(() => {
    return props?.options?.title ? props?.options?.title : "";
});

const content = computed(() => {
    return props?.options?.content ? props?.options?.content : "";
});

const showClose = computed(() => {
    return props?.options?.showClose;
});

const cancelBtnText = computed(() => {
    return props?.options?.cancelBtnText
        ? props?.options?.cancelBtnText
        : "Cancel";
});

const confirmBtnText = computed(() => {
    return props?.options?.confirmBtnText
        ? props?.options?.confirmBtnText
        : "Confirm";
});

watch(
    () => props.show,
    (newValue: boolean) => {
        showModal.value = newValue;
    },
    { immediate: true }
);

// 关闭退出提醒弹框
const onCloseModel = () => {
    emits("update:show", false);
};

const onConfirm = () => {
    emits("onConfirm");
    nextTick(() => {
        onCloseModel();
    });
};

const onCancel = () => {
    emits("onCalcel");
    nextTick(() => {
        onCloseModel();
    });
};

const _hanleClose = () => {
    onCancel();
};

const _handleConfirm = () => {
    onConfirm();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(NModal), {
    show: showModal.value,
    "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => ((showModal).value = $event)),
    "transform-origin": "center",
    "mask-closable": false,
    "auto-focus": false,
    to: __props.to
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (title.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("h2", null, _toDisplayString(title.value), 1)
              ]),
              (showClose.value)
                ? (_openBlock(), _createBlock(_unref(NIcon), {
                    key: 0,
                    class: "btn-icon",
                    size: "34",
                    onClick: onCancel
                  }, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createElementVNode("img", { src: _imports_0 }, null, -1)
                    ])),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (content.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("p", null, _toDisplayString(content.value), 1)
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", {
                class: "btn-sec btn-default mr16",
                onClick: _hanleClose
              }, [
                _createElementVNode("span", _hoisted_9, _toDisplayString(cancelBtnText.value), 1)
              ]),
              _createElementVNode("div", {
                class: "btn-sec btn-plain",
                onClick: _handleConfirm
              }, [
                _createElementVNode("span", _hoisted_10, _toDisplayString(confirmBtnText.value), 1)
              ])
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["show", "to"]))
}
}

})