/**
 * 消息alert
 */

import { Component } from "vue";

interface MessageOption {
    type?: "info" | "success" | "warning" | "error" | "loading";
    content?: string;
    duration?: number;
    showIcon?: boolean;
    icon?: string | Component;
    showClose?: boolean;
    placement?: string;
    onClose?: () => void;
    onLeave?: () => void;
}

export const MessageAlert = (content: string, option?: MessageOption) => {
    (window as any)?.$NAMessage.info(content, option);
};
