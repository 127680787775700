/**
 * post api
 * 1, Post Interface
 */
import type { DraftPostRequest, PostFilterRequest } from "@/types";
import httpRequest from "@/utils/request";

/**
 * getFilteredAllPostsInPage
 * @param param
 * @returns
 */
const getFilteredAllPostsInPage = (param: {
    currentPage?: number;
    pageSize?: number;
    procedureFilters: [];
    userRole: [];
}) => {
    return httpRequest({
        url: "/post/all_posts",
        method: "post",
        data: param,
    });
};

/**
 * saveDraftPost
 * @param param
 * @returns
 */
const saveDraftPost = (param: DraftPostRequest) => {
    return httpRequest({
        url: "/post/drafts",
        method: "post",
        data: param,
    });
};

/**
 * getDraftById
 * @param param
 * @returns
 */
const getDraftById = (param: { draftId: string }) => {
    return httpRequest({
        url: `/post/drafts/${param.draftId}`,
        method: "get",
        params: param,
    });
};

/**
 * EditPost
 * @param param
 * @returns
 */
const EditPost = (param: DraftPostRequest) => {
    return httpRequest({
        url: `/post/edit_posts`,
        method: "get",
        params: param,
    });
};

/**
 * filterPostsByCategoryAndAuthor
 * @param param
 * @returns
 */
const filterPostsByCategoryAndAuthor = (param: PostFilterRequest) => {
    return httpRequest({
        url: "/post/filter",
        method: "post",
        data: param,
    });
};

/**
 * fuzzySearchTags
 * @param param
 * @returns
 */
const fuzzySearchTags = (param: {
    currentPage?: number;
    pageSize?: number;
    tag?: string;
}) => {
    return httpRequest({
        url: "/post/fuzzySearchTags",
        method: "post",
        data: param,
    });
};

/**
 * getHighPost
 * @param param
 * @returns
 */
const getHighPost = (param: { memberId?: string }) => {
    return httpRequest({
        url: `/post/highlight/${param.memberId}`,
        method: "get",
        params: param,
    });
};

/**
 * likePost
 * @param param
 * @returns
 */
const likePost = (param: { postId?: number }) => {
    return httpRequest({
        url: `/post/like`,
        method: "post",
        data: param,
    });
};

/**
 * postLiker
 * @param param
 * @returns
 */
const postLiker = (param: {
    currentPage?: number;
    pageSize?: number;
    postId: number;
}) => {
    return httpRequest({
        url: `/post/postLiker`,
        method: "post",
        data: param,
    });
};

/**
 * Create post
 * @param param
 * @returns
 */
const createPost = (param: DraftPostRequest) => {
    return httpRequest({
        url: `/post/posts`,
        method: "post",
        data: param,
    });
};

/**
 * highlightPost
 * @param param
 * @returns
 */
const highlightPost = (param: { id: number }) => {
    return httpRequest({
        url: `/post/posts/${param.id}/highlight`,
        method: "post",
        data: param,
    });
};

/**
 * privatePost
 * @param param
 * @returns
 */
const privatePost = (param: { id: number }) => {
    return httpRequest({
        url: `/post/posts/${param.id}/private`,
        method: "post",
        data: param,
    });
};

/**
 * removeHighlightPost
 * @param param
 * @returns
 */
const removeHighlightPost = (param: { id: number }) => {
    return httpRequest({
        url: `/post/posts/${param.id}/remove_highlight`,
        method: "post",
        data: param,
    });
};

/**
 * removePrivatePost
 * @param param
 * @returns
 */
const removePrivatePost = (param: { id: number }) => {
    return httpRequest({
        url: `/post/posts/${param.id}/remove_private`,
        method: "post",
        data: param,
    });
};

/**
 * getPostById
 * @param param
 * @returns
 */
const getPostById = (param: { postId?: number }) => {
    return httpRequest({
        url: `/post/posts/${param.postId}`,
        method: "get",
        params: param,
    });
};

/**
 * getFilteredPostsInPage
 * @param param
 * @returns
 */
const getFilteredPostsInPage = (param: {
    currentPage?: number;
    pageSize?: number;
    filterType?: number;
    lat?: string;
    lon?: string;
}) => {
    return httpRequest({
        url: `/post/posts:page`,
        method: "get",
        params: param,
    });
};

/**
 * updateDisplay
 * @param param
 * @returns
 */
const updateDisplay = (param: { dynamicId: number; isDisplay: number }) => {
    return httpRequest({
        url: `/post/updateDisplay`,
        method: "post",
        data: param,
    });
};

/**
 * getWebPostById
 * @param param
 * @returns
 */
const getWebPostById = (param: { postId: string; memberId?: string }) => {
    return httpRequest({
        url: `/post/web/posts`,
        method: "get",
        params: param,
    });
};

/**
 * deletePost
 * @param param
 * @returns
 */
const deletePost = (param: { id: number }) => {
    return httpRequest({
        url: `/post/${param.id}`,
        method: "delete",
        params: param,
    });
};

/**
 * save post 收藏post
 * @param param
 * @returns
 */
const savePost = (param: { postId: number }) => {
    return httpRequest({
        url: `/post/collect`,
        method: "post",
        data: param,
    });
};

export default {
    getFilteredAllPostsInPage,
    saveDraftPost,
    getDraftById,
    EditPost,
    filterPostsByCategoryAndAuthor,
    fuzzySearchTags,
    getHighPost,
    likePost,
    postLiker,
    createPost,
    highlightPost,
    privatePost,
    removeHighlightPost,
    removePrivatePost,
    getPostById,
    getFilteredPostsInPage,
    updateDisplay,
    getWebPostById,
    deletePost,
    savePost,
};
