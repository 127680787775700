/**
 * register api
 */
import { UserRole } from "@/constant";
import httpRequest from "@/utils/request";

/**
 * clickVerification
 * @param param
 * @returns
 */
const clickVerification = (param: { email: string; userRole: UserRole }) => {
    return httpRequest({
        url: "/register/clickVerification",
        method: "post",
        data: param,
    });
};

/**
 * isConfirmEmail
 * @param param
 * @returns
 */
const isConfirmEmail = (param: { email: string; userRole: UserRole }) => {
    return httpRequest({
        url: "/register/isConfirmEmail",
        method: "get",
        params: param,
    });
};

/**
 * registerEmail | addUser
 * @param param
 * @returns
 */
const registerEmail = (param: {
    email: string;
    nickname: string;
    password: string;
    userRole: UserRole;
}) => {
    return httpRequest({
        url: "/register/email",
        method: "post",
        data: param,
    });
};

/**
 * registerFacebook
 * @param param
 * @returns
 */
const registerFacebook = (param: { facebookAccessToken: string }) => {
    return httpRequest({
        url: "/register/facebook",
        method: "post",
        data: param,
    });
};

/**
 * sendOtpByPhone | sendOtp
 * @param param
 * @returns
 */
const sendOtpByPhone = (param: { mobile: string }) => {
    return httpRequest({
        url: "/register/phone/send-otp",
        method: "post",
        data: param,
    });
};

/**
 * validateOtpByPhone | validateOtp
 * @param param
 * @returns
 */
const validateOtpByPhone = (param: {
    mobile: string;
    otp: string;
    userRole: UserRole;
}) => {
    return httpRequest({
        url: "/register/phone/validate-otp",
        method: "post",
        data: param,
    });
};

/**
 * verifyEmail
 * @param param
 * @returns
 */
const verifyEmail = (param: { token: string }) => {
    return httpRequest({
        url: "/register/verifyEmail",
        method: "get",
        params: param,
    });
};

export default {
    clickVerification,
    isConfirmEmail,
    registerEmail,
    registerFacebook,
    sendOtpByPhone,
    validateOtpByPhone,
    verifyEmail,
};
