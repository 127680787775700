import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import vue3GoogleLogin from "vue3-google-login";
// import { registerDirectives } from "./directives";

import "./registerServiceWorker";
import "./assets/main.css";
import { GOOGLE_CLIENTID } from "./constant";

const app = createApp(App);
// registerDirectives(app);
app.use(vue3GoogleLogin, {
    clientId: GOOGLE_CLIENTID,
});
app.use(store);
app.use(router);
app.mount("#app");
