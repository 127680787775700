/**
 * useInterestedList
 */

import { MessageAlert } from "./useMessageAlert";
import { computed, nextTick, onMounted, ref } from "vue";
import { ProcedureImgList, REQUEST_SCCUESS_CODE } from "@/constant";
import { useInterestedRelativeAct } from "./useInterestedRelativeAct";

export const useInterestedList = () => {
    const { _fetchAllInterestedListByApi } = useInterestedRelativeAct();
    const interestedList = ref<any[]>([]);
    const curInterestedList = computed(() => {
        const list = interestedList.value.map((ele: any) => {
            const targtEle: any = ProcedureImgList.find(
                (el) => el.categoryName === ele?.name
            );
            if (targtEle) {
                Object.assign(ele, targtEle);
            }
            return ele;
        });
        return list;
    });

    const _initGetAllInterestedList = async () => {
        const res: any = await _fetchAllInterestedListByApi();
        if (res?.code === REQUEST_SCCUESS_CODE) {
            const resData = res?.data;
            interestedList.value = resData;
        } else {
            const errMess = res?.msg || "fetch interested list failed";
            MessageAlert(errMess);
        }
    };

    const _initInterestedPage = () => {
        _initGetAllInterestedList();
    };

    onMounted(() => {
        nextTick(() => {
            _initInterestedPage();
        });
    });

    return { curInterestedList, _initInterestedPage };
};
