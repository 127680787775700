/**
 * store user
 */

import api from "@/api";
import { UserLoginState } from "../type";
import {
    LOGIN_TOKEN_KEY,
    REQUEST_SCCUESS_CODE,
    USER_INDENTIFY_KEY,
    USER_ROLE_KEY,
    UserRole,
    USER_INFO_KEY,
    USER_THIRD_LOGIN_INFO_KEY,
} from "@/constant";
import type { UserDto } from "@/types";
import storage from "@/utils/storage";
import { MessageAlert } from "@/composables/useMessageAlert";

export default {
    namespaced: true,
    state: (): UserLoginState => ({
        isLogined: false,
        showLoginModal: false,
        showRegisterModal: false,
        token: "",
        accoutType: 1,
        userRole: "",
        userInfo: null,
        userThirdInfo: null,
    }),
    mutations: {
        setLoginStatus(state: UserLoginState, value: boolean): void {
            state.isLogined = value;
        },
        setShowLoginModal(state: UserLoginState, value: boolean): void {
            state.showLoginModal = value;
        },
        setRegisterModal(state: UserLoginState, value: boolean): void {
            state.showRegisterModal = value;
        },
        setLoginToken(state: UserLoginState, value: string): void {
            state.token = value;
            storage.set(LOGIN_TOKEN_KEY, state.token);
        },
        setAccountType(state: UserLoginState, value: number): void {
            state.accoutType = value;
            storage.set(USER_INDENTIFY_KEY, state.accoutType);
        },
        setUserRole(state: UserLoginState, value: string): void {
            state.userRole = value;
            storage.set(USER_ROLE_KEY, state.userRole.toUpperCase());
        },
        setUserInf(state: UserLoginState, value: UserDto): void {
            state.userInfo = value;
            storage.set(USER_INFO_KEY, state.userInfo);
        },
        setUserThirdInf(state: UserLoginState, value: UserDto): void {
            state.userThirdInfo = value;
            storage.set(USER_THIRD_LOGIN_INFO_KEY, state.userThirdInfo);
        },
    },
    actions: {
        setLoginStatusAct({ commit }: any, value: boolean): void {
            commit("setLoginStatus", value);
        },
        setLoginModalStatusAct({ commit }: any, value: boolean): void {
            commit("setShowLoginModal", value);
        },
        setRegisterModalStatusAct({ commit }: any, value: boolean): void {
            commit("setRegisterModal", value);
        },
        setLoginTokenAct({ commit }: any, value: string): void {
            commit("setLoginToken", value);
        },
        setAccountTypeAct({ commit }: any, value: number): void {
            const curUserRole = value === 1 ? UserRole.User : UserRole.Doctor;
            commit("setAccountType", value);
            commit("setUserRole", curUserRole);
        },
        setUserInfoAct({ commit }: any, value: UserDto): void {
            commit("setUserInf", value);
        },
        setUserThirdInfoAct({ commit }: any, value: UserDto): void {
            commit("setUserThirdInf", value);
        },
        setUserLoginInfoAfterSuccess({ dispatch }: any, data: any) {
            const token = data.token;
            const accountType = data.userRole === "USER" ? 1 : 2;
            dispatch("setLoginStatusAct", true);
            dispatch("setAccountTypeAct", accountType);
            dispatch("setLoginTokenAct", token);
            dispatch("setUserThirdInfoAct", data);
        },
        async userLoginIn({ getters, dispatch }: any, params: any) {
            try {
                const res: any =
                    Number(getters.getAccoutType) === 1
                        ? await api.loginUser(params)
                        : await api.loginDoctor(params);
                if (res?.code === REQUEST_SCCUESS_CODE) {
                    //登录成功
                    dispatch("setUserLoginInfoAfterSuccess", res.data);
                } else {
                    dispatch("setLoginStatusAct", false);
                    const errMess = res?.msg || "login failed";
                    MessageAlert(errMess);
                }
                return res;
            } catch (error: any) {
                dispatch("setLoginStatusAct", false);
                const errMess = error?.message || "login failed";
                MessageAlert(errMess);
            }
        },
        async userLoginSocial({ dispatch }: any, params: any) {
            try {
                const res: any = await api.loginSocial(params);
                if (res?.code === REQUEST_SCCUESS_CODE) {
                    //登录成功
                    dispatch("setUserLoginInfoAfterSuccess", res.data);
                } else {
                    dispatch("setLoginStatusAct", false);
                    const errMess = res?.msg || "login Social failed";
                    MessageAlert(errMess);
                }
            } catch (error: any) {
                dispatch("setLoginStatusAct", false);
                const errMess = error?.message || "login Social failed";
                MessageAlert(errMess);
            }
        },
        async fetchUserInfo({ dispatch }: any) {
            try {
                const res: any = await api.fetchUserProfile();
                if (res?.code === REQUEST_SCCUESS_CODE) {
                    //获取用户信息
                    const userInfo = res.data;
                    dispatch("setUserInfoAct", userInfo);
                    dispatch("setLoginStatusAct", true);
                } else {
                    dispatch("setLoginStatusAct", false);
                    const errMess = res?.msg || "fetch user info failed";
                    console.error("log setLoginStatusAct", errMess);
                    //MessageAlert(errMess);
                }
            } catch (error: any) {
                dispatch("setLoginStatusAct", false);
                const errMess = error?.message || "fetch user info failed";
                console.error("log setLoginStatusAct", errMess);
                //MessageAlert(errMess);
            }
        },
        async userRegisterEmail({ dispatch }: any, params: any) {
            try {
                const res: any = await api.registerEmail(params);
                if (res?.code === REQUEST_SCCUESS_CODE) {
                    //登录成功
                    dispatch("setUserLoginInfoAfterSuccess", res.data);
                } else {
                    dispatch("setLoginStatusAct", false);
                    const errMess = res?.msg || "register failed";
                    MessageAlert(errMess);
                }
            } catch (error: any) {
                dispatch("setLoginStatusAct", false);
                const errMess = error?.message || "register failed";
                MessageAlert(errMess);
            }
        },
        userLoginOut({ dispatch }: any) {
            dispatch("setLoginStatusAct", false);
            dispatch("setAccountTypeAct", 0);
            dispatch("setLoginTokenAct", "");
            dispatch("setUserInfoAct", "");
            storage.clear();
        },
    },
    getters: {
        getLoginStatus(state: UserLoginState) {
            return state.isLogined;
        },
        getShowLoginModal(state: UserLoginState) {
            return state.showLoginModal;
        },
        getLoginToken(state: UserLoginState) {
            return state.token || storage.get(LOGIN_TOKEN_KEY);
        },
        getAccoutType(state: UserLoginState) {
            return state.accoutType || Number(storage.get(USER_INDENTIFY_KEY));
        },
        getUserRole(state: UserLoginState) {
            return state.userRole || storage.get(USER_ROLE_KEY);
        },
        getUserInfo(state: UserLoginState) {
            return state.userInfo || storage.get(USER_INFO_KEY);
        },
        getUserThirdType(state: UserLoginState) {
            const cacheData = storage.get(USER_THIRD_LOGIN_INFO_KEY);
            return state.userThirdInfo?.userType || cacheData?.userType;
        },
        getUserThirdEmail(state: UserLoginState) {
            const cacheData = storage.get(USER_THIRD_LOGIN_INFO_KEY);
            return state.userThirdInfo?.email || cacheData?.email;
        },
    },
};
