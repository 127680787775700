<template>
    <div class="forget-password-message-cont">
        <!-- 步骤展示 -->
        <!-- <div v-if="forgetPasswordStep === 1 || forgetPasswordStep === 1 || forgetPasswordStep === 1" class="step-bar-cont">
                <n-steps :current="1" status="process">
                    <n-step description="Enter Your Email" />
                    <n-step description="Verify your identity" />
                    <n-step description="Change your Password" />
                </n-steps>
            </div> -->
        <div
            v-if="
                forgetPasswordStep === 1 ||
                forgetPasswordStep === 2 ||
                forgetPasswordStep === 3
            "
            class="step-bar-cont"
        >
            <div class="number-cont">
                <div
                    v-if="forgetPasswordStep === 1"
                    class="number current-step"
                >
                    1
                </div>
                <img
                    v-if="forgetPasswordStep === 2 || forgetPasswordStep === 3"
                    src="@/assets/images/layout/step_done_icon.png"
                />

                <div class="line current-step"></div>

                <div
                    v-if="forgetPasswordStep === 1 || forgetPasswordStep === 2"
                    class="number"
                    :class="{ 'current-step': forgetPasswordStep === 2 }"
                >
                    2
                </div>
                <img
                    v-if="forgetPasswordStep === 3"
                    src="@/assets/images/layout/step_done_icon.png"
                />

                <div
                    class="line"
                    :class="{ 'current-step': forgetPasswordStep === 3 }"
                ></div>

                <div
                    class="number"
                    :class="{ 'current-step': forgetPasswordStep === 3 }"
                >
                    3
                </div>
            </div>

            <div class="text-cont">
                <div class="current-step">Enter Your Email</div>
                <div
                    :class="{
                        'current-step':
                            forgetPasswordStep === 2 ||
                            forgetPasswordStep === 3,
                    }"
                >
                    Verify your identity
                </div>
                <div :class="{ 'current-step': forgetPasswordStep === 3 }">
                    Change your Password
                </div>
            </div>
        </div>

        <!-- 输入邮箱--step1 -->
        <div v-if="forgetPasswordStep === 1" class="email-address-input-cont">
            <n-form
                ref="forgetPasswordEmailFormRef"
                :model="forgetPasswordEmail"
                :rules="forgetPasswordEmailRules"
            >
                <n-form-item
                    path="email"
                    label="Email Address"
                    :theme-overrides="formItemThemeOverrides"
                >
                    <n-input
                        v-model:value="forgetPasswordEmail.email"
                        placeholder="Enter your Email Address"
                        size="large"
                        @keydown.enter.prevent
                    >
                        <template #suffix>
                            <n-icon
                                v-show="showForgotEmailClearBtn"
                                class="btn-icon"
                                size="16"
                                @click="_handleClearForgotEmail"
                            >
                                <img
                                    src="@/assets/icons/icon_input_clear_plain.svg"
                                />
                            </n-icon>
                        </template>
                    </n-input>
                </n-form-item>
            </n-form>

            <div class="verify-btn-cont">
                <n-button
                    :color="
                        !isDisabledForgetPasswordEmail
                            ? '#000000'
                            : 'rgba(0, 0, 0, 0.2)'
                    "
                    :text-color="
                        !isDisabledForgetPasswordEmail ? '#88EEFF' : '#ffffff'
                    "
                    :disabled="isDisabledForgetPasswordEmail"
                    icon-placement="right"
                    @click="_handleValidEmail"
                >
                    <template v-if="isDisabledForgetPasswordEmail">
                        Next
                    </template>
                    <template v-else> Verify </template>
                    <template #icon>
                        <img
                            style="margin-left: 12px; width: 26px; height: 14px"
                            src="@/assets/images/layout/next_icon.png"
                        />
                    </template>
                </n-button>
            </div>

            <div class="log-in-tip">
                Back to
                <span @click="_handleShowLogin">Log in</span>
            </div>
        </div>

        <!-- 验证邮箱--step2 -->
        <div v-if="forgetPasswordStep === 2" class="verify-identity-cont">
            <div class="has-sended-message">
                We have sent you an email to verify that your email address is
                <span>marketing@charm-life.com</span>
            </div>

            <div class="btn-group">
                <div class="custom-btn resend-btn" @click="_hanleResendEmail">
                    Resend Email
                </div>
                <div class="custom-btn check-btn" @click="_hanleCheckVerify">
                    Check Verify
                </div>
            </div>
            <!-- <div class="re-send-verify-email" @click="forgetPasswordStep = 3">
                Resend Verification Email
            </div> -->
        </div>

        <!-- 设置新密码--step3 -->
        <div v-if="forgetPasswordStep === 3" class="new-password-input-cont">
            <n-form
                ref="forgetPasswordFormRef"
                :model="forgetPasswordModel"
                :rules="forgetPasswordModelRules"
            >
                <n-form-item
                    path="password"
                    label="New Password"
                    :theme-overrides="formItemThemeOverrides"
                >
                    <n-input
                        v-model:value="forgetPasswordModel.password"
                        :type="showForgotPassword ? 'text' : 'password'"
                        placeholder="Enter Your New Password"
                        size="large"
                        @keydown.enter.prevent
                    >
                        <template #suffix>
                            <n-icon
                                class="btn-icon"
                                size="24"
                                @click="_handleShowPassword"
                            >
                                <img
                                    v-if="showForgotPassword"
                                    src="@/assets/icons/icon_layout_eye.svg"
                                />
                                <img
                                    v-else
                                    src="@/assets/icons/icon_layout_password.svg"
                                />
                            </n-icon>
                            <n-icon
                                v-show="showForgotPasswordClearBtn"
                                class="btn-icon"
                                size="16"
                                @click="_handleClearForgotPassword"
                            >
                                <img
                                    src="@/assets/icons/icon_input_clear_plain.svg"
                                />
                            </n-icon>
                        </template>
                    </n-input>
                </n-form-item>
                <n-form-item
                    path="confirmPassword"
                    label="Re-enter your New Password"
                    :theme-overrides="formItemThemeOverrides"
                >
                    <n-input
                        v-model:value="forgetPasswordModel.confirmPassword"
                        :type="showForgotConfirmPassword ? 'text' : 'password'"
                        placeholder="Confirm Your New Password"
                        size="large"
                        @keydown.enter.prevent
                    >
                        <template #suffix>
                            <n-icon
                                class="btn-icon"
                                size="24"
                                @click="_handleShowConfirmPassword"
                            >
                                <img
                                    v-if="showForgotConfirmPassword"
                                    src="@/assets/icons/icon_layout_eye.svg"
                                />
                                <img
                                    v-else
                                    src="@/assets/icons/icon_layout_password.svg"
                                />
                            </n-icon>
                            <n-icon
                                v-show="showForgotConfirmPasswordClearBtn"
                                class="btn-icon"
                                size="16"
                                @click="_handleClearForgotConfirmPassword"
                            >
                                <img
                                    src="@/assets/icons/icon_input_clear_plain.svg"
                                />
                            </n-icon>
                        </template>
                    </n-input>
                </n-form-item>
            </n-form>

            <div class="send-btn-cont">
                <n-button
                    :color="
                        !isDisabledForgetPasswordModel
                            ? '#000000'
                            : 'rgba(0, 0, 0, 0.2)'
                    "
                    :text-color="
                        !isDisabledForgetPasswordModel ? '#88EEFF' : '#ffffff'
                    "
                    :disabled="isDisabledForgetPasswordModel"
                    icon-placement="right"
                    @click="handleResetPassword"
                >
                    <template v-if="isDisabledForgetPasswordModel">
                        Next
                    </template>
                    <template v-else> Send </template>
                    <template #icon>
                        <img
                            style="margin-left: 12px; width: 26px; height: 14px"
                            src="@/assets/images/layout/next_icon.png"
                        />
                    </template>
                </n-button>
            </div>
        </div>

        <!-- 设置新密码成功--step4 -->
        <div v-if="forgetPasswordStep === 4" class="edit-password-success">
            <div class="img-cont">
                <img src="@/assets/images/layout/edit_password_success.png" />
            </div>

            <div class="title">Password changed succesfully</div>

            <div class="describe">
                Your password has been changed successfully. we will let you
                know if there are more problems with your account
            </div>

            <div class="back-to-login-btn-cont">
                <n-button
                    color="#000000"
                    text-color="#88EEFF"
                    icon-placement="right"
                    @click="_handleShowLogin"
                >
                    Back to Login
                    <template #icon>
                        <img
                            style="margin-left: 12px; width: 26px; height: 14px"
                            src="@/assets/images/layout/next_icon.png"
                        />
                    </template>
                </n-button>
            </div>
        </div>

        <!-- 设置新密码失败--step5 -->
        <div v-if="forgetPasswordStep === 5" class="edit-password-fail">
            <div class="img-cont">
                <img src="@/assets/images/layout/edit_password_fail.png" />
            </div>

            <div class="title">Something went wrong</div>

            <div class="describe">Please give it another whirl</div>

            <div class="return-btn-cont">
                <n-button
                    color="#000000"
                    text-color="#88EEFF"
                    @click="forgetPasswordStep = 3"
                    >Return</n-button
                >
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { NForm, NFormItem, NInput, NButton, NIcon } from "naive-ui";
import type {
    FormInst,
    FormItemProps,
    FormItemRule,
    FormRules,
} from "naive-ui";
import { ref, computed, watch, onMounted, nextTick } from "vue";
import { useStore } from "vuex";
import api from "@/api";
import { REQUEST_SCCUESS_CODE } from "@/constant";
import { LOGIN_TOKEN_KEY } from "@/constant";
import storage from "@/utils/storage";
import { AxiosResponse } from "axios";
import { MessageAlert } from "@/composables/useMessageAlert";
import { validateEmail, validatePassword } from "@/utils/util";
import { useRegisterAct } from "@/composables/useRegisterAct";

const props = defineProps({
    currentStep: {
        type: Number,
        default: () => {
            return 1;
        },
    },
});
const emits = defineEmits(["update:currentStep", "onLogin"]);

type FormItemThemeOverrides = NonNullable<FormItemProps["themeOverrides"]>;
const formItemThemeOverrides: FormItemThemeOverrides = {
    feedbackPadding: "12px 0",
};
const store = useStore();
const { _clickVerifyEmailByApi, _isConfirmEmailByApi, _resetPasswordByApi } =
    useRegisterAct();
const cacheUserRole = ref<string>("USER");
const forgetPasswordStep = ref<number>(1);
const showForgotPassword = ref<boolean>(false);
const showForgotConfirmPassword = ref<boolean>(false);
const forgetPasswordEmailFormRef = ref<FormInst | null>(null);
const forgetPasswordEmail = ref<any>({
    email: null,
});
const isDisabledForgetPasswordEmail = computed(() => {
    return forgetPasswordEmail.value.email ? false : true;
});

const forgetPasswordEmailRules: FormRules = {
    email: [
        {
            required: true,
            trigger: ["input"],
            validator: (rule: FormItemRule, value: any) => {
                //console.log(rule, value);
                if (!value) {
                    return new Error("Please input");
                } else if (!validateEmail(value)) {
                    return new Error("Please enter a valid email address");
                }
                return true;
            },
        },
    ],
};

const showForgotEmailClearBtn = computed(() => {
    return forgetPasswordEmail.value.email ? true : false;
});

const forgetPasswordFormRef = ref<FormInst | null>(null);
const forgetPasswordModel = ref<any>({
    password: null,
    confirmPassword: null,
});
const isDisabledForgetPasswordModel = computed(() => {
    return forgetPasswordModel.value.password &&
        forgetPasswordModel.value.confirmPassword
        ? false
        : true;
});

const showForgotPasswordClearBtn = computed(() => {
    return forgetPasswordModel.value.password ? true : false;
});

const showForgotConfirmPasswordClearBtn = computed(() => {
    return forgetPasswordModel.value.confirmPassword ? true : false;
});

const forgetPasswordModelRules: FormRules = {
    password: [
        {
            required: true,
            trigger: ["input"],
            validator: (rule: FormItemRule, value: any) => {
                //console.log(rule, value);
                if (!value) {
                    return new Error("Please input");
                } else if (!validatePassword(value)) {
                    return new Error("password must 6~16 letters and numbers");
                }
                return true;
            },
        },
    ],
    confirmPassword: [
        {
            required: true,
            trigger: ["input"],
            validator: (rule: FormItemRule, value: any) => {
                //console.log(rule, value);
                if (!value) {
                    return new Error("Please input");
                } else if (!validatePassword(value)) {
                    return new Error("password must 6~16 letters and numbers");
                }
                return true;
            },
        },
    ],
};

const _handleClearForgotEmail = () => {
    forgetPasswordEmail.value.email = "";
};

const _handleClearForgotPassword = () => {
    forgetPasswordModel.value.password = "";
};

const _handleShowPassword = () => {
    showForgotPassword.value = !showForgotPassword.value;
};

const _handleShowConfirmPassword = () => {
    showForgotConfirmPassword.value = !showForgotConfirmPassword.value;
};

const _handleClearForgotConfirmPassword = () => {
    forgetPasswordModel.value.confirmPassword = "";
};

const updateCreateAccountStep = (step: number) => {
    emits("update:currentStep", step);
};

watch(
    () => props.currentStep,
    (newV) => {
        forgetPasswordStep.value = newV;
    },
    { immediate: true }
);

watch(
    () => forgetPasswordStep.value,
    (newV) => {
        updateCreateAccountStep(newV);
    },
    { immediate: true }
);

const _handleValidEmail = () => {
    if (isDisabledForgetPasswordEmail.value) {
        MessageAlert("Please input correct email");
        return;
    }
    //forgetPasswordStep.value = 2;
    forgetPasswordEmailFormRef.value?.validate(async (errors) => {
        if (!errors) {
            const params: any = {
                email: forgetPasswordEmail.value.email,
                userRole: cacheUserRole.value,
            };
            const res: any = await _clickVerifyEmailByApi(params);
            if (res?.code === REQUEST_SCCUESS_CODE) {
                //下一步
                // const successMess = res?.msg || "email resend success";
                // MessageAlert(successMess);
                forgetPasswordStep.value = 2;
            } else {
                const errMess = res?.msg || "email verify failed";
                MessageAlert(errMess);
            }
        } else {
            //console.error(errors);
            const errMess =
                errors[0] && errors[0][0]?.message
                    ? errors[0][0]?.message
                    : "Please enter a valid email address";
            MessageAlert(errMess);
        }
    });
};

const _hanleResendEmail = () => {
    _handleValidEmail();
};

const _hanleCheckVerify = async () => {
    //forgetPasswordStep.value = 3;
    const params: any = {
        email: forgetPasswordEmail.value.email,
        userRole: cacheUserRole.value,
    };
    const res: any = await _isConfirmEmailByApi(params);
    if (res?.code === REQUEST_SCCUESS_CODE) {
        //下一步
        forgetPasswordStep.value = 3;
    } else {
        let errMess = res?.msg || "email confirm failed";
        MessageAlert(errMess);
    }
};

const handleResetPassword = () => {
    if (isDisabledForgetPasswordModel.value) {
        MessageAlert("Please enter password");
        return;
    }
    if (
        forgetPasswordModel.value.password !==
        forgetPasswordModel.value.confirmPassword
    ) {
        MessageAlert("confirmPassword must be consistent");
        return;
    }
    //forgetPasswordStep.value = 4;
    forgetPasswordFormRef.value?.validate(async (error) => {
        if (!error) {
            const params = {
                email: forgetPasswordEmail.value.email,
                newPassword: forgetPasswordModel.value.password,
                confirmPassword: forgetPasswordModel.value.confirmPassword,
            };
            const res: any = await _resetPasswordByApi(params);
            if (res?.code === REQUEST_SCCUESS_CODE) {
                const token = res.data.token;
                const accountType = res.data.userRole === "USER" ? 1 : 2;
                token && storage.set(LOGIN_TOKEN_KEY, token);
                store.dispatch("user/setAccountTypeAct", accountType);
                forgetPasswordStep.value = 4;
            } else {
                const errMess = res?.msg || "reset password failed";
                MessageAlert(errMess);
            }
        } else {
            MessageAlert("password must 6~16 letters and numbers");
        }
    });
};

const _handleShowLogin = () => {
    emits("onLogin");
};

const _initSlefPage = () => {
    const cacheUserRoleLocal = store.getters["user/getUserRole"];
    cacheUserRole.value = cacheUserRoleLocal;
};

onMounted(() => {
    nextTick(() => {
        _initSlefPage();
    });
});
</script>

<style lang="scss" scoped>
.forget-password-message-cont {
    .step-bar-cont {
        margin-top: 76px;
        color: rgba(0, 0, 0, 0.2);
        text-align: center;
        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        .number-cont {
            padding: 0 50px;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .number {
                width: 28px;
                height: 28px;
                border-radius: 50%;
                border: 2px solid rgba(0, 0, 0, 0.2);
                box-sizing: border-box;
                background-color: #fff;
                line-height: 26px;

                &.current-step {
                    color: #000;
                    border: 2px solid #000;
                }
            }

            img {
                width: 28px;
                height: 28px;
            }

            .line {
                width: 102px;
                height: 2px;
                background-color: rgba(0, 0, 0, 0.2);

                &.current-step {
                    background-color: #000;
                }
            }
        }

        .text-cont {
            margin-top: 6px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            div.current-step {
                color: #000;
            }
        }
    }

    .email-address-input-cont {
        margin-top: 48px;

        .verify-btn-cont {
            :deep(.n-button) {
                width: 100%;
                height: 60px;
                font-family: "PingFang SC";
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px;
            }
        }

        .log-in-tip {
            margin-top: 32px;
            color: rgba(0, 0, 0, 0.8);
            text-align: center;
            font-family: "PingFang SC";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            span {
                color: rgba(255, 82, 82, 0.8);
                text-decoration-line: underline;
                cursor: pointer;
            }
        }
    }

    .verify-identity-cont {
        margin-top: 76px;
        text-align: center;
        font-size: 16px;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        .has-sended-message {
            color: rgba(0, 0, 0, 0.2);

            span {
                color: rgba(0, 0, 0, 0.6);
            }
        }

        .btn-group {
            display: flex;
            margin-top: 103px;
            align-items: center;
            justify-content: flex-end;

            .custom-btn {
                margin-left: 12px;
                padding: 12px 28px;
                font-family: "PingFang SC";
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px; /* 100% */
                cursor: pointer;
            }

            .resend-btn {
                color: rgba(255, 82, 82, 0.8);
                background-color: #ffffff;
                text-decoration: underline;
            }

            .check-btn {
                color: #ffffff;
                background-color: #070707;
            }
        }
    }

    .new-password-input-cont {
        margin-top: 48px;

        .send-btn-cont {
            margin-top: 8px;

            :deep(.n-button) {
                width: 100%;
                height: 60px;
                font-family: "PingFang SC";
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px;
            }
        }
    }

    .edit-password-success {
        margin-top: 74px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        color: rgba(0, 0, 0, 0.2);
        text-align: center;
        font-family: "Open Sans";
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        .img-cont {
            width: 112px;
            height: 112px;

            img {
                width: 112px;
                height: 112px;
            }
        }

        .title {
            margin-top: 24px;
            color: rgba(0, 0, 0, 0.6);
        }

        .describe {
            margin-top: 24px;
            font-size: 16px;
            font-weight: 600;
        }

        .back-to-login-btn-cont {
            margin-top: 54px;
            width: 100%;

            :deep(.n-button) {
                width: 100%;
                height: 60px;
                font-family: "PingFang SC";
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px;
            }
        }
    }

    .edit-password-fail {
        margin-top: 74px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        color: rgba(0, 0, 0, 0.2);
        text-align: center;
        font-family: "Open Sans";
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        .img-cont {
            width: 112px;
            height: 112px;

            img {
                width: 112px;
                height: 112px;
            }
        }

        .title {
            margin-top: 24px;
            color: #ff5252;
        }

        .describe {
            margin-top: 24px;
            color: rgba(0, 0, 0, 0.2);
            font-size: 16px;
            font-weight: 600;
        }

        .return-btn-cont {
            margin-top: 54px;
            width: 100%;

            :deep(.n-button) {
                width: 100%;
                height: 60px;
                font-family: "PingFang SC";
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px;
            }
        }
    }
}
@media screen and (max-width: 1024px) and (min-width: 744px) {
}
@media screen and (max-width: 744px) {
    .forget-password-message-cont {
        .step-bar-cont {
            .number-cont {
                padding: 0 20px;
            }
            .text-cont {
                > div {
                    min-width: 150px;
                }
            }
        }

        .email-address-input-cont {
            margin-top: 36px;

            .log-in-tip {
                margin-top: 24px;
            }
        }

        .verify-identity-cont {
            font-size: 14px;

            .btn-group {
                margin-top: 56px;
                gap: 12px;

                .custom-btn {
                    margin-left: 0;
                    padding: 12px 20px;
                }
            }
        }

        .new-password-input-cont {
            margin-top: 36px;
        }

        .edit-password-success {
            margin-top: 38px;
            font-size: 20px;

            .img-cont {
                width: 60px;
                height: 60px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: fill;
                }
            }
        }

        .edit-password-fail {
            margin-top: 38px;
            font-size: 20px;

            .img-cont {
                width: 60px;
                height: 60px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: fill;
                }
            }
        }
    }
}
</style>
