/**
 * procedure api: procedures-related interface
 */
import httpRequest from "@/utils/request";

/**
 * findAllProcedure
 * @param param
 * @returns
 */
const findAllProcedure = (param?: any) => {
    return httpRequest({
        url: `/procedure`,
        method: "get",
        params: param,
    });
};

/**
 * detailedInstruments
 * @param param
 * @returns
 */
const detailedInstruments = (param: { category: string }) => {
    return httpRequest({
        url: `/procedure/detailedInstruments/${param.category}`,
        method: "get",
        params: param,
    });
};

/**
 * getAllInstruments
 * @param param
 * @returns
 */
const getAllInstruments = (param: { instrument: string }) => {
    return httpRequest({
        url: `/procedure/instruments`,
        method: "get",
        params: param,
    });
};

/**
 * procedureTrendy
 * @param param
 * @returns
 */
const procedureTrendy = (param: any) => {
    return httpRequest({
        url: `/procedure/trendy`,
        method: "get",
        params: param,
    });
};

/**
 * procedureCategory
 * @param param
 * @returns
 */
const procedureCategory = (param: { category: string }) => {
    return httpRequest({
        url: `/procedure/${param.category}`,
        method: "get",
        params: param,
    });
};

export default {
    findAllProcedure,
    detailedInstruments,
    getAllInstruments,
    procedureTrendy,
    procedureCategory,
};
