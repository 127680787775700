/**
 * constant varibale
 */

export const LOGIN_TOKEN_KEY = "login-token";
export const USER_INDENTIFY_KEY = "accountType";
export const USER_ROLE_KEY = "userRole";
export const USER_INFO_KEY = "userInfo";
export const USER_THIRD_LOGIN_INFO_KEY = "userThirdLoginInfo";

export const REQUEST_SCCUESS_CODE = 100;
export const REQUEST_SCCUESS_STATUS = "Success";

export const VUE_APP_IMG_BUCKET_NAME = "charm-post-img";
export const VUE_APP_IMG_BUCKET_REGION = "us-west-1";
export const MAX_FILE_SIZE = 100000 * 1024;
export const GOOGLE_CLIENTID =
    "1034793734928-td2qeg4g3rsjesij5rfqg00a4hs5egjp.apps.googleusercontent.com";

export enum UserRole {
    User = "USER",
    Doctor = "DOCTOR",
}

export const WeekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const EnglishMonth = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export const MenuTabsList = [
    {
        title: "Home",
        name: "Home",
    },
    {
        title: "Doctors",
        name: "Doctors",
    },
    {
        title: "Posts",
        name: "Posts",
    },
    {
        title: "Procedures",
        name: "Procedures",
    },
    {
        title: "Instruments",
        name: "Instruments",
    },
];

export enum SimpleLoginType {
    Google = "google",
    Apple = "apple",
    Facebook = "facebook",
}

export const ProcedureImgList = [
    {
        imgSrc: require("@/assets/images/procedure/types/botox_injections.svg"),
        titleName: "Botox Injection",
        categoryName: "botox_injections",
    },
    {
        imgSrc: require("@/assets/images/procedure/types/breast_augmentation.svg"),
        titleName: "Breast Augmentation",
        categoryName: "breast_augmentation",
    },
    {
        imgSrc: require("@/assets/images/procedure/types/chemical_peels.svg"),
        titleName: "Chemical Peels",
        categoryName: "chemical_peels",
    },
    {
        imgSrc: require("@/assets/images/procedure/types/chin_implants.svg"),
        titleName: "Chin Implants",
        categoryName: "chin_implants",
    },
    {
        imgSrc: require("@/assets/images/procedure/types/fox_eyes.svg"),
        titleName: "Fox Eyes",
        categoryName: "fox_eyes",
    },
    {
        imgSrc: require("@/assets/images/procedure/types/facelift.svg"),
        titleName: "Face lift",
        categoryName: "facelift",
    },
    {
        imgSrc: require("@/assets/images/procedure/types/laser_hair_removal.svg"),
        titleName: "Laser Hair Removal",
        categoryName: "laser_hair_removal",
    },
    {
        imgSrc: require("@/assets/images/procedure/types/lip_augmentation.svg"),
        titleName: "Lip augmentation",
        categoryName: "lip_augmentation",
    },
    {
        imgSrc: require("@/assets/images/procedure/types/otoplasty.svg"),
        titleName: "Otoplasty",
        categoryName: "otoplasty",
    },
    {
        imgSrc: require("@/assets/images/procedure/types/neck_contouring.svg"),
        titleName: "Neck Contouring",
        categoryName: "neck_contouring",
    },
    {
        imgSrc: require("@/assets/images/procedure/types/teeth_whitening.svg"),
        titleName: "Teeth Whitening",
        categoryName: "teeth_whitening",
    },
    {
        imgSrc: require("@/assets/images/procedure/types/tummy_tuck.svg"),
        titleName: "Tummy Tuck",
        categoryName: "tummy_tuck",
    },
];

export const PaginationPageSizesList = [
    {
        label: "10 Page",
        value: 10,
    },
    {
        label: "20 Page",
        value: 20,
    },
];

export const GenderTypeList = [
    {
        label: "Male",
        value: "1",
    },
    {
        label: "Female",
        value: "2",
    },
    {
        label: "Other",
        value: "3",
    },
];

export const InstrumentTypeList = [
    {
        imgSrc: require("@/assets/images/instrument/thermage.svg"),
        name: "thermage",
        categoryName: "Thermage",
    },
    {
        imgSrc: require("@/assets/images/instrument/inmode.svg"),
        name: "inmode",
        categoryName: "Inmode",
    },
    {
        imgSrc: require("@/assets/images/instrument/coolsculpting.svg"),
        name: "coolsculpting",
        categoryName: "Coolsculpting",
    },
    {
        imgSrc: require("@/assets/images/instrument/fraxel_laser.svg"),
        name: "fraxel_laser",
        categoryName: "Fraxel Laser",
    },
];

export const TitlePositionTypeList = [
    {
        label: "Medical Student",
        value: "Medical Student",
    },
    {
        label: "Intern",
        value: "Intern",
    },
    {
        label: "Resident",
        value: "Resident",
    },
    {
        label: "Physician",
        value: "Physician",
    },
];

export const EducationalBackgroundList = [
    {
        label: "Doctors-in-training",
        value: "Doctors-in-training",
    },
    {
        label: "Foundation doctor",
        value: "Foundation doctor",
    },
    {
        label: "Specialty Registrar",
        value: "Specialty Registrar",
    },
    {
        label: "Middle-grade doctors",
        value: "Middle-grade doctors",
    },
    {
        label: "Staff grade doctor",
        value: "Staff grade doctor",
    },
    {
        label: "Specialty doctors",
        value: "Specialty doctors",
    },
    {
        label: "Associate specialist doctor",
        value: "Associate specialist doctor",
    },
    {
        label: "Consultants",
        value: "Consultants",
    },
];

export const DistanceOptionsList = [
    {
        label: "All",
        value: "0",
    },
    {
        label: "Distance",
        value: "1",
    },
    {
        label: "Shortest Distance",
        value: "2",
    },
    {
        label: "Recommend",
        value: "3",
    },
];

export const VerificateOptionsList = [
    {
        label: "All",
        value: "0",
    },
    {
        label: "Verified",
        value: "1",
    },
    {
        label: "Unverified",
        value: "2",
    },
];

export const SatisfactionRateOptionsList = [
    {
        label: "0 star",
        value: "0",
    },
    {
        label: "1 star",
        value: "1",
    },
    {
        label: "2 star",
        value: "2",
    },
    {
        label: "3 star",
        value: "3",
    },
    {
        label: "4 star",
        value: "4",
    },
    {
        label: "5 star",
        value: "5",
    },
];
