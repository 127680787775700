import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, unref as _unref, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/images/layout/step_done_icon.png'
import _imports_1 from '@/assets/images/layout/close_icon.png'


const _hoisted_1 = { class: "doctor-authentication-cont" }
const _hoisted_2 = { class: "step-bar-cont" }
const _hoisted_3 = { class: "number-cont" }
const _hoisted_4 = {
  key: 0,
  class: "number current-step"
}
const _hoisted_5 = {
  key: 1,
  src: _imports_0
}
const _hoisted_6 = { class: "text-cont" }
const _hoisted_7 = {
  key: 0,
  class: "part-one-profile-input"
}
const _hoisted_8 = {
  key: 1,
  class: "part-two-profile-input"
}

import { NInput } from 'naive-ui';
import { ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'DoctorAuthentication',
  emits: ['closeDoctorAuthenticationModal'],
  setup(__props, { emit: __emit }) {

const emit = __emit;

const setProFilesStep = ref<number>(1);

const personalMessageModel = ref<any>({
    email: null,
    practice: null,
})

// 关闭弹窗
const closeDoctorAuthenticationModal = () => {
    emit('closeDoctorAuthenticationModal');
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[14] || (_cache[14] = _createElementVNode("div", { class: "doctor-authentication-title" }, "Set Up Your Profile", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (setProFilesStep.value === 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, "1"))
          : _createCommentVNode("", true),
        (setProFilesStep.value === 2)
          ? (_openBlock(), _createElementBlock("img", _hoisted_5))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(["line", { 'current-step': setProFilesStep.value === 2 }])
        }, null, 2),
        _createElementVNode("div", {
          class: _normalizeClass(["number", { 'current-step': setProFilesStep.value === 2 }])
        }, "2", 2)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _cache[10] || (_cache[10] = _createElementVNode("div", { class: "current-step" }, "Enter Your Profiles", -1)),
        _createElementVNode("div", {
          class: _normalizeClass({ 'current-step': setProFilesStep.value === 2 })
        }, "Verify your identity", 2)
      ])
    ]),
    (setProFilesStep.value === 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _cache[11] || (_cache[11] = _createElementVNode("div", { class: "title" }, "Personal Information", -1)),
          _createVNode(_unref(NInput), {
            value: personalMessageModel.value.email,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((personalMessageModel.value.email) = $event)),
            placeholder: "Enter your Email Address",
            size: "large",
            onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"]))
          }, null, 8, ["value"]),
          _createVNode(_unref(NInput), {
            value: personalMessageModel.value.email,
            "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((personalMessageModel.value.email) = $event)),
            placeholder: "Enter your Email Address",
            size: "large",
            onKeydown: _cache[3] || (_cache[3] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"]))
          }, null, 8, ["value"]),
          _createVNode(_unref(NInput), {
            value: personalMessageModel.value.email,
            "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((personalMessageModel.value.email) = $event)),
            placeholder: "Enter your Email Address",
            size: "large",
            onKeydown: _cache[5] || (_cache[5] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"]))
          }, null, 8, ["value"]),
          _cache[12] || (_cache[12] = _createElementVNode("div", { class: "title" }, "Practice Information", -1)),
          _createVNode(_unref(NInput), {
            value: personalMessageModel.value.email,
            "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((personalMessageModel.value.email) = $event)),
            placeholder: "Enter your Email Address",
            size: "large",
            onKeydown: _cache[7] || (_cache[7] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"]))
          }, null, 8, ["value"]),
          _createVNode(_unref(NInput), {
            value: personalMessageModel.value.email,
            "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((personalMessageModel.value.email) = $event)),
            placeholder: "Enter your Email Address",
            size: "large",
            onKeydown: _cache[9] || (_cache[9] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["enter"]))
          }, null, 8, ["value"])
        ]))
      : _createCommentVNode("", true),
    (setProFilesStep.value === 2)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "close-cont",
      onClick: closeDoctorAuthenticationModal
    }, _cache[13] || (_cache[13] = [
      _createElementVNode("img", { src: _imports_1 }, null, -1)
    ]))
  ]))
}
}

})