/**
 * home api
 * 1, upload files
 */
import httpRequest from "@/utils/request";

/**
 * fetchUploadFIleSign
 * @param param
 * @returns
 */
const fetchUploadFileSign = (param: { fileName: string }) => {
    return httpRequest({
        url: `/upload/sign`,
        method: "get",
        params: param,
    });
};

/**
 * healthyCheck
 * @param param
 * @returns
 */
const healthyCheck = (param?: any) => {
    return httpRequest({
        url: `/healthy_check`,
        method: "get",
        params: param,
    });
};

const addParticipants = (param: {
    meetingId: string;
    req: {
        custom_participant_id: string;
        name: string;
        picture: string;
        preset_name: string;
    };
}) => {
    return httpRequest({
        url: `/meet/add_meeting/${param.meetingId}`,
        method: "post",
        data: param,
    });
};

const userCreateLiveStreaming = (param: any) => {
    return httpRequest({
        url: `/meet/create_meeting`,
        method: "post",
        data: param,
    });
};

const updateMeeting = (param: {
    meetingId: string;
    req: {
        live_stream_on_start: boolean;
        preferred_region: string;
        record_on_start: boolean;
        status: string;
        title: string;
    };
}) => {
    return httpRequest({
        url: `/meet/update_meeting/${param.meetingId}`,
        method: "post",
        data: param,
    });
};

const getFaqsByCategoryId = (param: { categoryId: string }) => {
    return httpRequest({
        url: `/faq/${param.categoryId}`,
        method: "get",
        params: param,
    });
};

export default {
    fetchUploadFileSign,
    healthyCheck,
    addParticipants,
    userCreateLiveStreaming,
    updateMeeting,
    getFaqsByCategoryId,
};
