import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/images/layout/app_logo.png'
import _imports_1 from '@/assets/images/layout/prev_step_icon.png'
import _imports_2 from '@/assets/images/layout/close_icon.png'


const _hoisted_1 = { class: "login-register-modal" }
const _hoisted_2 = {
  key: 0,
  class: "logo-cont"
}
const _hoisted_3 = {
  key: 1,
  class: "login-container"
}
const _hoisted_4 = {
  key: 2,
  class: "register-container"
}
const _hoisted_5 = {
  key: 3,
  class: "forget-password-container"
}

import { ref, computed, onMounted, nextTick } from "vue";
import LoginMain from "./setModules/LoginMain.vue";
import RegisterMain from "./setModules/RegisterMain.vue";
import ForgetPasswordMain from "./setModules/ForgetPasswordMain.vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginAndRegister',
  emits: ["closeLoginAndRegisterModal"],
  setup(__props, { emit: __emit }) {

const emits = __emit;
const store = useStore();
const router = useRouter();

const showMessageCont = ref<string>("login");
const currentLoginStep = ref<number>(1);
const createAccountStep = ref<number>(1);
const forgetPasswordStep = ref<number>(1);
const curCompletePersonalInformationStep = ref<number>(1);

// 弹窗标题
const loginAndRegisterTitle = computed(() => {
    let res = "Log in";
    switch (showMessageCont.value) {
        case "login":
            res =
                currentLoginStep.value === 1 ? "Join Charm as a..." : "Log in";
            break;
        case "register":
            if (createAccountStep.value === 2) {
                res = "Verify your identity";
            } else if (createAccountStep.value === 4) {
                res =
                    curCompletePersonalInformationStep.value === 1
                        ? "Complete personal information"
                        : "Your Interests";
            } else {
                res = "Create Account";
            }
            break;
        case "forgetPassword":
            res =
                forgetPasswordStep.value === 1 ||
                forgetPasswordStep.value === 2 ||
                forgetPasswordStep.value === 3
                    ? "Forgotten Password？"
                    : "Charm";
            break;
        default:
            break;
    }
    return res;
});

const onChangeCurUserInfoStep = (step: number) => {
    curCompletePersonalInformationStep.value = step;
};

const _handleShowLogin = () => {
    showMessageCont.value = "login";
};

const _goToPageDoctorProfile = () => {
    router.push({ path: "/user/profile", query: { from: "user" } });
};

const _handleShowRegister = async (type?: string) => {
    if (type === "thirdlogin") {
        const cacheUserThirdType = store.getters["user/getUserThirdType"];
        if (cacheUserThirdType === "0") {
            showMessageCont.value = "register";
            createAccountStep.value = 3;
        } else {
            await store.dispatch("user/fetchUserInfo");
            _goToPageDoctorProfile();
            nextTick(() => {
                closeLoginAndRegisterModal();
            });
        }
    } else {
        showMessageCont.value = "register";
    }
};

// 忘记密码
const _handleEditPassword = () => {
    showMessageCont.value = "forgetPassword";
    forgetPasswordStep.value = 1;
};

// 注册时后退一步
const prevRegisterStep = () => {
    if (createAccountStep.value === 1) {
        showMessageCont.value = "login";
    } else if (createAccountStep.value === 6) {
        createAccountStep.value = 4;
    } else {
        if (
            createAccountStep.value === 4 &&
            curCompletePersonalInformationStep.value === 2
        ) {
            curCompletePersonalInformationStep.value = 1;
        } else {
            createAccountStep.value--;
        }
    }
};

// 忘记密码时后退一步
const prevForgetPasswordStep = () => {
    if (forgetPasswordStep.value === 1) {
        showMessageCont.value = "login";
    } else if (forgetPasswordStep.value === 4) {
        forgetPasswordStep.value -= 2;
    } else {
        forgetPasswordStep.value--;
    }
};

// 关闭登陆注册弹框
const closeLoginAndRegisterModal = () => {
    emits("closeLoginAndRegisterModal");
    showMessageCont.value = "login";
    createAccountStep.value = 1;
};

const _initUserLoginInfo = () => {
    //清理缓存 undo
    //清理用户信息 undo
};

onMounted(() => {
    nextTick(() => {
        _initUserLoginInfo();
    });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (createAccountStep.value !== 5 && createAccountStep.value !== 6)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _cache[3] || (_cache[3] = _createElementVNode("img", { src: _imports_0 }, null, -1)),
          _createElementVNode("div", null, _toDisplayString(loginAndRegisterTitle.value), 1)
        ]))
      : _createCommentVNode("", true),
    (showMessageCont.value === 'login')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(LoginMain, {
            currentStep: currentLoginStep.value,
            "onUpdate:currentStep": _cache[0] || (_cache[0] = ($event: any) => ((currentLoginStep).value = $event)),
            onOnClose: closeLoginAndRegisterModal,
            onOnRegister: _handleShowRegister,
            onOnForgotPassword: _handleEditPassword
          }, null, 8, ["currentStep"])
        ]))
      : _createCommentVNode("", true),
    (showMessageCont.value === 'register')
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(RegisterMain, {
            currentStep: createAccountStep.value,
            "onUpdate:currentStep": _cache[1] || (_cache[1] = ($event: any) => ((createAccountStep).value = $event)),
            "cur-base-info-step": curCompletePersonalInformationStep.value,
            onOnLogin: _handleShowLogin,
            onOnChangeuserInfoStep: onChangeCurUserInfoStep,
            onOnClose: closeLoginAndRegisterModal
          }, null, 8, ["currentStep", "cur-base-info-step"])
        ]))
      : _createCommentVNode("", true),
    (showMessageCont.value === 'forgetPassword')
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(ForgetPasswordMain, {
            currentStep: forgetPasswordStep.value,
            "onUpdate:currentStep": _cache[2] || (_cache[2] = ($event: any) => ((forgetPasswordStep).value = $event)),
            onOnLogin: _handleShowLogin
          }, null, 8, ["currentStep"])
        ]))
      : _createCommentVNode("", true),
    (showMessageCont.value === 'register' && createAccountStep.value !== 5)
      ? (_openBlock(), _createElementBlock("div", {
          key: 4,
          class: "prev-step-cont",
          onClick: prevRegisterStep
        }, _cache[4] || (_cache[4] = [
          _createElementVNode("img", { src: _imports_1 }, null, -1)
        ])))
      : _createCommentVNode("", true),
    (showMessageCont.value === 'forgetPassword')
      ? (_openBlock(), _createElementBlock("div", {
          key: 5,
          class: "prev-step-cont",
          onClick: prevForgetPasswordStep
        }, _cache[5] || (_cache[5] = [
          _createElementVNode("img", { src: _imports_1 }, null, -1)
        ])))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "close-cont",
      onClick: closeLoginAndRegisterModal
    }, _cache[6] || (_cache[6] = [
      _createElementVNode("img", { src: _imports_2 }, null, -1)
    ]))
  ]))
}
}

})