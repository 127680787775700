import axios from "axios";
import { LOGIN_TOKEN_KEY } from "@/constant";
// import router from "@/router";
import { MessageAlert } from "@/composables/useMessageAlert";

const httpRequest = axios.create({
    baseURL: "/charmApi",
    withCredentials: true,
    timeout: 3000,
});

// 请求拦截器
httpRequest.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem(LOGIN_TOKEN_KEY) as string;
        //console.log("config===>", token);
        if (token) {
            config["headers"].Authorization = `Bearer ${token}`;
        }
        //console.log("config===>", config);
        return config;
    },
    (error) => {
        console.log("请求失败", error);
        return Promise.reject(error);
    }
);

// 响应拦截器
httpRequest.interceptors.response.use(
    (response) => {
        //console.log("response====>", response);
        const result = response.data;
        if (result) {
            if (result.httpcode) result.httpcode = result.status;
            return result;
        }
        return response;
    },
    (error) => {
        //console.log("响应失败", error);
        const { response } = error;
        const result = response?.data || null;
        const status = result?.status || response?.status;
        if (status === 401) {
            //localStorage.removeItem(LOGIN_TOKEN_KEY);
            //router.push({ name: "login" });
        } else if (status === 403) {
            // 无权限 跳转到无权限页面
            //router.push({ name: "noPermission" });
            const errMess = "Sorry, please login first to browse more!";
            MessageAlert(errMess);
        } else if (status === 404) {
            // 路由不存在
            // 跳转到404页面
            //router.push({ name: "notFound" });
        }
        return Promise.reject(error);
    }
);

export default httpRequest;
