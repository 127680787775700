iframe
<template>
    <n-modal
        v-model:show="showModal"
        transform-origin="center"
        :mask-closable="false"
        :auto-focus="false"
        :to="to"
    >
        <div class="preview-container">
            <div class="preview-header-contianer">
                <div class="title">
                    <h2></h2>
                </div>
                <n-icon class="btn-icon" size="34" @click="onCancel">
                    <img src="@/assets/icons/icon_video_preview_close.svg" />
                </n-icon>
            </div>
            <div class="preview-mainer-container">
                <div class="video-container">
                    <!-- <iframe
                        v-if="resourcesSrc"
                        class="video-control"
                        :src="resourcesSrc"
                    ></iframe> -->
                    <div v-if="resourcesSrc" class="video-control">
                        <!-- <video
                            ref="videoRef"
                            preload="metadata"
                            loop
                            :src="resourcesSrc"
                            controls
                        >
                            Your browser does not support the video tag.
                        </video> -->
                        <VideoPlayer
                            class="video-player"
                            :src="resourcesSrc"
                            playsinline
                            controls
                            :volume="0.6"
                            :playback-rates="[0.7, 1.0, 1.5, 2.0]"
                            :height="670"
                            :width="960"
                            :autoplay="false"
                            reportTouchActivity
                            @mounted="handleMounted"
                            @pause="handleEvent($event)"
                        />
                    </div>
                    <img
                        v-else
                        class="thumb-img"
                        src="@/assets/images/doctor/video_thumb_img.png"
                        alt="video_thumb_img"
                    />
                </div>
                <div v-show="videoPaused" class="overlay-container">
                    <n-icon
                        class="btn-icon play-icon"
                        color="#ffffff"
                        @click="_handleVideoPlay"
                    >
                        <!-- <img src="@/assets/icons/icon_video_preview_play.svg" /> -->
                        <PlayCircleOutline v-if="videoPaused" />
                        <PauseCircleOutline v-else />
                    </n-icon>
                    <div v-show="showEditFlag" class="more-container">
                        <n-popover
                            class="custom-popover"
                            placement="bottom-end"
                            trigger="click"
                            :show-arrow="false"
                            to=".more-container"
                        >
                            <template #trigger>
                                <n-icon class="btn-icon">
                                    <img
                                        src="@/assets/icons/icon_video_preview_more.svg"
                                    />
                                </n-icon>
                            </template>
                            <div class="action-container">
                                <div class="action-list">
                                    <div
                                        class="action-item"
                                        @click="_handleEditVideo"
                                    >
                                        <span class="text">Edit video</span>
                                    </div>
                                    <div
                                        class="action-item"
                                        @click="_handleDeleteVideo"
                                    >
                                        <span class="text">Delete video</span>
                                    </div>
                                </div>
                            </div>
                        </n-popover>
                    </div>
                </div>
            </div>
        </div>
    </n-modal>
</template>

<script lang="ts" setup>
import {
    computed,
    nextTick,
    onMounted,
    reactive,
    ref,
    shallowRef,
    watch,
} from "vue";
import { NModal, NPopover, NIcon } from "naive-ui";
import { PlayCircleOutline, PauseCircleOutline } from "@vicons/ionicons5";
import { VideoPlayer } from "vue-video-player";
import videojs from "video.js";
import "video.js/dist/video-js.css";

type VideoJsPlayer = ReturnType<typeof videojs>;

const props = defineProps({
    show: {
        type: Boolean,
        default: () => {
            return false;
        },
    },
    to: {
        type: [String, HTMLElement],
        default: () => {
            return "body";
        },
    },
    options: {
        type: Object,
        default: () => {
            return {
                showEdit: false,
                src: "",
            };
        },
    },
});

const emits = defineEmits([
    "update:show",
    "onCloseModal",
    "onConfirmModal",
    "onEditVideo",
    "onDeleteVideo",
]);

const videoRef = ref();
const videoPlayer = shallowRef<VideoJsPlayer>();
const showModal = ref<boolean>(false);
const resourcesSrc = computed(() => {
    return props.options?.src;
});
const videoPaused = ref<boolean | undefined>(true);
const showEditFlag = computed(() => {
    return props.options?.showEdit && videoPaused.value;
});
watch(
    () => props.show,
    (newValue: boolean) => {
        showModal.value = newValue;
        if (newValue) {
            videoPaused.value = true;
        }
    },
    { immediate: true }
);

const handleMounted = (payload: any) => {
    videoPlayer.value = payload.player;
    //console.log("Basic player mounted", payload);
};

const handleEvent = (log: any) => {
    //console.log("Basic player event", log);
    if (log?.type === "pause") {
        videoPaused.value = true;
    }
};

// 显示退出提醒弹框
const handleShowModal = () => {
    //showModal.value = true;
    emits("update:show", true);
};

// 关闭退出提醒弹框
const onCloseModel = () => {
    //showModal.value = false;
    emits("update:show", false);
};

const onConfirm = () => {
    emits("onConfirmModal");
    nextTick(() => {
        onCloseModel();
    });
};

const onCancel = () => {
    emits("onCloseModal");
    nextTick(() => {
        onCloseModel();
    });
};

const _handleEditVideo = () => {
    emits("onEditVideo");
};

const _handleDeleteVideo = () => {
    emits("onDeleteVideo");
};

const _handleVideoPlay = () => {
    videoPaused.value = !videoPaused.value;
    if (videoPaused.value) {
        videoPlayer.value?.pause();
    } else {
        videoPlayer.value?.play();
    }
};

const _InitVideoPlayer = () => {
    videoPaused.value = true;
};

onMounted(() => {
    _InitVideoPlayer();
});
</script>

<style lang="scss" scoped>
.preview-container {
    width: 50%;
    box-shadow: none;

    .preview-header-contianer {
        display: flex;
        padding-left: 20px;
        align-items: center;
        justify-content: space-between;

        .title {
            flex: 1;
            height: 44px;
            text-align: center;

            > h2 {
                margin: 10px auto;
                padding: 10px 0;
                text-align: center;
                color: #070707;
                font-family: "Open Sans";
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }

        .btn-icon {
            cursor: pointer;
        }
    }

    .preview-mainer-container {
        position: relative;
        .video-container {
            width: 100%;

            .thumb-img {
                width: 100%;
                height: auto;
                object-fit: fill;
            }

            .video-control {
                width: 100%;
                height: 670px;
                border: none;

                :deep(.d-player-state) {
                    display: none !important;
                }

                .video-player {
                    max-width: 100%;
                }

                :deep(.video-js .vjs-big-play-button) {
                    visibility: hidden;
                }

                video {
                    width: 100%;
                    height: 100%;
                    object-fit: scale-down;
                }
            }
        }

        .overlay-container {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 1;

            .btn-icon {
                cursor: pointer;
            }

            .play-icon {
                font-size: 200px;
            }
        }

        .more-container {
            position: absolute;
            top: 25px;
            right: 50px;

            .btn-icon {
                display: inline-block;
                width: 8px;
                height: 38px;
                cursor: pointer;

                > img {
                    width: 100%;
                    height: 100%;
                    object-fit: fill;
                }
            }

            :deep(.custom-popover) {
                margin-top: 20px;
                padding: 28px 0;
                background-color: rgba(30, 26, 25, 0.8);
            }

            .action-container {
                margin: 0px 28px;
                .action-list {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 15px;
                    .action-item {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 12px 32px;
                        background-color: #352c29;
                        cursor: pointer;
                        .text {
                            display: inline-block;
                            min-width: 140px;
                            text-align: center;
                            color: #fbfcff;
                            font-family: "Open Sans";
                            font-size: 22px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) and (min-width: 744px) {
    .preview-container {
        width: 100%;

        .preview-mainer-container {
            width: calc(100% - 40px);
            margin: 0 auto;
            .video-container {
                .video-control {
                    height: 530px;

                    .video-player {
                        max-height: 100%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 744px) {
    .preview-container {
        width: 100%;

        .preview-header-contianer{
            width: calc(100% - 24px);
            margin: 0 auto;
            padding-left: 0;
        }

        .preview-mainer-container {
            width: calc(100% - 24px);
            margin: 0 auto;
            .video-container {
                .video-control {
                    height: 530px;

                    .video-player {
                        max-height: 100%;
                    }
                }
            }
            .overlay-container {
                .play-icon {
                    font-size: 80px;
                }
                .more-container {
                    position: absolute;
                    top: 20px;
                    right: 20px;
                }
            }
        }
    }
}
</style>
