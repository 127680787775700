<template>
    <div class="choose-user-identity">
        <div class="user-identity-list">
            <div
                class="user-identity-item"
                :class="{ selected: curChooseCareerSelected === 1 }"
                @click.stop="_hdanleChooseCareerSelected(1)"
            >
                <div class="user-identity-item-img-cont">
                    <img src="@/assets/images/layout/member_career_icon.png" />
                </div>
                <div class="user-identity-item-name">Member</div>
            </div>

            <div
                class="user-identity-item"
                :class="{ selected: curChooseCareerSelected === 2 }"
                @click.stop="_hdanleChooseCareerSelected(2)"
            >
                <div class="user-identity-item-img-cont">
                    <img src="@/assets/images/layout/doctor_career_icon.png" />
                </div>
                <div class="user-identity-item-name">Doctor</div>
            </div>
        </div>

        <div class="next-btn-cont">
            <n-button
                :color="
                    !isDisabledChooseCareerSelected
                        ? '#000000'
                        : 'rgba(0, 0, 0, 0.2)'
                "
                :text-color="
                    !isDisabledChooseCareerSelected ? '#88EEFF' : '#ffffff'
                "
                icon-placement="right"
                :disabled="isDisabledChooseCareerSelected"
                @click="_handleChooseCareerNext"
            >
                Next
                <template #icon>
                    <img
                        style="margin-left: 12px; width: 26px; height: 14px"
                        src="@/assets/images/layout/next_icon.png"
                    />
                </template>
            </n-button>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { NButton } from "naive-ui";
import { ref, computed, watch } from "vue";
import { MessageAlert } from "@/composables/useMessageAlert";
import { UserRole } from "@/constant";

const props = defineProps({
    curRoleType: {
        type: String,
        default: () => {
            return "";
        },
    },
});
const emits = defineEmits(["onNext"]);

const curChooseCareerSelected = ref<number>(0);
const isDisabledChooseCareerSelected = computed(() => {
    return curChooseCareerSelected.value ? false : true;
});

watch(
    () => props.curRoleType,
    (newV) => {
        curChooseCareerSelected.value =
            newV === UserRole.User.toUpperCase()
                ? 1
                : newV === UserRole.Doctor.toUpperCase()
                ? 2
                : 0;
    },
    { immediate: true }
);

const _hdanleChooseCareerSelected = (type: number) => {
    curChooseCareerSelected.value = type;
};

// 注册-选择完职业之后点击next按钮
const _handleChooseCareerNext = () => {
    if (isDisabledChooseCareerSelected.value) {
        MessageAlert("Please choose a User identity");
        return;
    }
    emits("onNext", curChooseCareerSelected.value);
};
</script>

<style lang="scss" scoped>
.choose-user-identity {
    .user-identity-list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .user-identity-item {
            width: 232px;
            height: 96px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            background-color: $global-bg-color;
            cursor: pointer;

            &.selected {
                border: 2px solid #000;
            }

            .user-identity-item-img-cont {
                width: 80px;
                height: 80px;
                border-radius: 50%;
                background-color: #fff;
                overflow: hidden;

                img {
                    position: relative;
                    top: 10px;
                    width: 80px;
                    //height: 80px;
                }
            }

            .user-identity-item-name {
                margin-left: 16px;
                color: rgba(0, 0, 0, 0.8);
                font-family: "PingFang SC";
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }
    }

    .next-btn-cont {
        margin-top: 48px;

        :deep(.n-button) {
            width: 100%;
            height: 60px;
            font-family: "PingFang SC";
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
        }
    }
}
@media screen and (max-width: 1024px) and (min-width: 744px) {
}
@media screen and (max-width: 744px) {
    .choose-user-identity {
        .user-identity-list {
            .user-identity-item {
                width: 50%;
                height: 48px;
                overflow: hidden;

                .user-identity-item-img-cont {
                    width: 40px;
                    height: 44px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: fill;
                    }
                }

                .user-identity-item-name {
                    margin-left: 8px;
                    font-size: 18px;
                }
            }
        }
    }
}
</style>
