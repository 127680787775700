/**
 * api的入口文件
 * https://whtestwb.hitecloud.cn/charmapi/doc.html#/home
 */

import login from "./login";
import register from "./register";
import other from "./other";
import post from "./post";
import doctor from "./doctor";
import procedure from "./procedure";
import instrument from "./instrument";
import user from "./user";

export default {
    ...login,
    ...register,
    ...other,
    ...post,
    ...doctor,
    ...procedure,
    ...instrument,
    ...user,
};
