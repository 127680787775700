/**
 * user api:
 * 1, user-related interface
 * 2, user related interface
 * 3, User follow definition
 */
import type {
    SetDoctorProfileRequest,
    CheckPasswordRequest,
    SetCouponRequest,
} from "@/types";
import httpRequest from "@/utils/request";
import qs from "qs";

/**
 * getMyPosts
 * @param param
 * @returns
 */
const getMyPosts = (param: { currentPage?: number; pageSize?: number }) => {
    return httpRequest({
        url: "/user_action/Myposts",
        method: "post",
        data: param,
    });
};

/**
 * addComment
 * @param param
 * @returns
 */
const addComment = (param: { dynamicId?: number; text?: string }) => {
    return httpRequest({
        url: "/user_action/actions/comment",
        method: "post",
        data: param,
    });
};

/**
 * followUser
 * @param param
 * @returns
 */
const followUser = (param: { userId?: string }) => {
    return httpRequest({
        url: "/user_action/actions/follow",
        method: "post",
        data: param,
    });
};

/**
 * replyToComment
 * @param param
 * @returns
 */
const replyToComment = (param: { commentId?: number; text?: string }) => {
    return httpRequest({
        url: "/user_action/actions/reply",
        method: "post",
        data: param,
    });
};

/**
 * replyDoctorComment
 * @param param
 * @returns
 */
const replyDoctorComment = (param: {
    commentId?: number;
    text?: string;
    replyId?: number;
}) => {
    return httpRequest({
        url: "/doctor/actions/reply",
        method: "post",
        data: param,
    });
};

/**
 * unfollowUser
 * @param param
 * @returns
 */
const unfollowUser = (param: { userId?: string }) => {
    return httpRequest({
        url: "/user_action/actions/unfollow",
        method: "post",
        data: param,
    });
};

/**
 * getDoctorProfile
 * @param param
 */
const seeDoctorProfile = (param: any) => {
    return httpRequest({
        url: `/user_action/doctor_profile`,
        method: "get",
        params: param,
    });
};

/**
 * userLikedPost
 * @param param
 * @returns
 */
const userLikedPost = (param: { postId: number }) => {
    return httpRequest({
        url: `/user_action/like/post/${param.postId}`,
        method: "post",
        data: param,
    });
};

/**
 * userLikedCommet
 * @param param
 * @returns
 */
const userLikedCommet = (param: { commentId: number }) => {
    return httpRequest({
        url: `/user_action/like_comment`,
        method: "post",
        data: qs.stringify(param),
    });
};

/**
 * likedPosts
 * @param param
 * @returns
 */
const userLikedAllPosts = (param: {
    currentPage?: number;
    pageSize?: number;
}) => {
    return httpRequest({
        url: `/user_action/likedPosts`,
        method: "post",
        data: param,
    });
};

/**
 * setDoctorProfile
 * @param param
 * @returns
 */
const setDoctorProfile = (param: SetDoctorProfileRequest) => {
    return httpRequest({
        url: `/user_action/set_doctor_profile`,
        method: "post",
        data: param,
    });
};

/************   appointment relative ********/

/**
 * userAppointDoctor
 * @param param
 * @returns
 */
const userAppointDoctor = (param: {
    currentPage?: number;
    pageSize?: number;
}) => {
    return httpRequest({
        url: `/user/appointment-doctor`,
        method: "post",
        data: param,
    });
};

/**
 * userCheckAppointDetail
 * @param param
 * @returns
 */
const userCheckAppointDetail = (param: {
    currentPage?: number;
    pageSize?: number;
}) => {
    return httpRequest({
        url: `/user/appointment-user`,
        method: "post",
        data: param,
    });
};

/**
 * userCheckPassword
 * @param param
 * @returns
 */
const userCheckPassword = (param: CheckPasswordRequest) => {
    return httpRequest({
        url: `/user/check_password`,
        method: "post",
        data: param,
    });
};

/**
 * userContactUs
 * @param param
 * @returns
 */
const userContactUs = (param: {
    email_address?: string;
    message?: string;
    name?: string;
}) => {
    return httpRequest({
        url: `/user/contact_us`,
        method: "post",
        data: param,
    });
};

/**
 * userCouponOrder
 * @param param
 * @returns
 */
const userCouponOrder = (param: {
    currentPage?: number;
    pageSize?: number;
}) => {
    return httpRequest({
        url: `/user/coupon_order`,
        method: "post",
        data: param,
    });
};

/**
 * fetchUserProfile
 * @param param
 * @returns
 */
const fetchUserProfile = (param?: any) => {
    return httpRequest({
        url: `/user/fetch_user_profile`,
        method: "get",
        params: param,
    });
};

/**
 * getFollowersList
 * @param param
 * @returns
 */
const getFollowersList = (param: {
    currentPage?: number;
    pageSize?: number;
}) => {
    return httpRequest({
        url: `/user/followers`,
        method: "post",
        data: param,
    });
};

/**
 * getFollowsList
 * @param param
 * @returns
 */
const getFollowsList = (param: { currentPage?: number; pageSize?: number }) => {
    return httpRequest({
        url: `/user/follows`,
        method: "post",
        data: param,
    });
};

/**
 * getUserEditProfileData
 * @param param
 * @returns
 */
const getUserEditProfileData = (param?: any) => {
    return httpRequest({
        url: `/user/get_edit_user_profile`,
        method: "get",
        params: param,
    });
};

/**
 * userSetAppointTime
 * @param param
 * @returns
 */
const userSetAppointTime = (param: {
    aboutTime?: string;
    dateTime?: string;
}) => {
    return httpRequest({
        url: `/user/set_appointment_time`,
        method: "post",
        data: param,
    });
};

/**
 * userSetCoupon
 * @param param
 * @returns
 */
const userSetCoupon = (param: SetCouponRequest) => {
    return httpRequest({
        url: `/user/set_coupon`,
        method: "post",
        data: param,
    });
};

/**
 * userSetTheirProfile
 * @param param
 * @returns
 */
const userSetTheirProfile = (param: CheckPasswordRequest) => {
    return httpRequest({
        url: `/user/set_user_profile`,
        method: "post",
        data: param,
    });
};

/**
 * userUpdateEmail
 * @param param
 * @returns
 */
const userUpdateEmail = (param: { email: string }) => {
    return httpRequest({
        url: `/user/user_profile/update_email`,
        method: "post",
        data: param,
    });
};

/**
 * userVerifyUpdatedEmail
 * @param param
 * @returns
 */
const userVerifyUpdatedEmail = (param: { code: string }) => {
    return httpRequest({
        url: `/user/user_profile/verification_email`,
        method: "post",
        data: param,
    });
};

/************   User follow definition ********/

/**
 * followTheUser
 * @param param
 * @returns
 */
const followTheUser = (param: { userId?: number }) => {
    return httpRequest({
        url: `/users/follow`,
        method: "post",
        data: param,
    });
};

/**
 * fetchFollowingUserList
 * @param param
 * @returns
 */
const fetchFollowingUserList = (param: {
    currentPage?: number;
    pageSize?: number;
}) => {
    return httpRequest({
        url: `/users/followedMembers`,
        method: "post",
        data: param,
    });
};

/**
 * fetchFollowerUserList
 * @param param
 * @returns
 */
const fetchFollowerUserList = (param: {
    currentPage?: number;
    pageSize?: number;
}) => {
    return httpRequest({
        url: `/users/followerMembers`,
        method: "post",
        data: param,
    });
};

/**
 * unfollowTheUser
 * @param param
 * @returns
 */
const unfollowTheUser = (param: { userId?: number }) => {
    return httpRequest({
        url: `/users/unfollow`,
        method: "post",
        data: param,
    });
};

/**
 * get interested
 * @param param
 * @returns
 */
const getInterestedList = (param?: any) => {
    return httpRequest({
        url: `/user/interested`,
        method: "get",
        params: param,
    });
};

/**
 * set Interested
 * @param param
 * @returns
 */
const setUserInterested = (param: {
    memberId?: string;
    interestedId?: string;
}) => {
    return httpRequest({
        url: `/user/set_interested`,
        method: "post",
        data: param,
    });
};

export default {
    getMyPosts,
    addComment,
    followUser,
    replyToComment,
    replyDoctorComment,
    unfollowUser,
    seeDoctorProfile,
    userLikedPost,
    userLikedCommet,
    userLikedAllPosts,
    setDoctorProfile,

    userAppointDoctor,
    userCheckAppointDetail,
    userCheckPassword,
    userContactUs,
    userCouponOrder,
    fetchUserProfile,
    getFollowersList,
    getFollowsList,
    getUserEditProfileData,
    userSetAppointTime,
    userSetCoupon,
    userSetTheirProfile,
    userUpdateEmail,
    userVerifyUpdatedEmail,

    followTheUser,
    fetchFollowingUserList,
    fetchFollowerUserList,
    unfollowTheUser,

    getInterestedList,
    setUserInterested,
};
