<template>
    <n-modal
        v-model:show="showModal"
        transform-origin="center"
        :mask-closable="false"
        :auto-focus="false"
        :to="to"
    >
        <div class="prompt-model-container">
            <div v-if="title" class="model-header-contianer">
                <div class="title">
                    <h2>{{ title }}</h2>
                </div>
                <n-icon
                    v-if="showClose"
                    class="btn-icon"
                    size="34"
                    @click="onCancel"
                >
                    <img src="@/assets/icons/icon_close.svg" />
                </n-icon>
            </div>
            <div v-if="content" class="model-mainer-container">
                <div class="content-cont">
                    <p>{{ content }}</p>
                </div>
            </div>
            <div class="model-footer-container">
                <div class="footer-btn-control">
                    <div class="btn-group-control">
                        <div
                            class="btn-sec btn-default mr16"
                            @click="_hanleClose"
                        >
                            <span class="btn-text"> {{ cancelBtnText }} </span>
                        </div>
                        <div class="btn-sec btn-plain" @click="_handleConfirm">
                            <span class="btn-text"> {{ confirmBtnText }} </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </n-modal>
</template>

<script lang="ts" setup>
import { computed, nextTick, ref, watch } from "vue";
import { NButton, NModal, NIcon } from "naive-ui";

const props = defineProps({
    show: {
        type: Boolean,
        default: () => {
            return false;
        },
    },
    to: {
        type: [String, HTMLElement],
        default: () => {
            return "body";
        },
    },
    options: {
        type: Object,
        default: () => {
            return null;
        },
    },
});

const emits = defineEmits(["update:show", "onCalcel", "onConfirm"]);

const showModal = ref<boolean>(false);

const title = computed(() => {
    return props?.options?.title ? props?.options?.title : "";
});

const content = computed(() => {
    return props?.options?.content ? props?.options?.content : "";
});

const showClose = computed(() => {
    return props?.options?.showClose;
});

const cancelBtnText = computed(() => {
    return props?.options?.cancelBtnText
        ? props?.options?.cancelBtnText
        : "Cancel";
});

const confirmBtnText = computed(() => {
    return props?.options?.confirmBtnText
        ? props?.options?.confirmBtnText
        : "Confirm";
});

watch(
    () => props.show,
    (newValue: boolean) => {
        showModal.value = newValue;
    },
    { immediate: true }
);

// 关闭退出提醒弹框
const onCloseModel = () => {
    emits("update:show", false);
};

const onConfirm = () => {
    emits("onConfirm");
    nextTick(() => {
        onCloseModel();
    });
};

const onCancel = () => {
    emits("onCalcel");
    nextTick(() => {
        onCloseModel();
    });
};

const _hanleClose = () => {
    onCancel();
};

const _handleConfirm = () => {
    onConfirm();
};
</script>

<style lang="scss" scoped>
.prompt-model-container {
    min-width: 440px;
    background-color: #fff;
    .model-header-contianer {
        display: flex;
        position: relative;
        padding: 10px 20px;
        align-items: center;
        justify-content: space-between;

        .title {
            flex: 1;
            text-align: center;

            > h2 {
                margin: 10px auto;
                padding: 10px 0;
                text-align: center;
                color: #070707;
                font-family: "Open Sans";
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }

        .btn-icon {
            cursor: pointer;
        }
    }

    .model-header-contianer::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        height: 1px;
        background-color: #f7f2ed;
    }

    .model-mainer-container {
        padding: 28px;

        .content-cont {
            > p {
                margin: 0;
                padding: 0;
                color: #070707;
                font-family: "Open Sans";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 160%; /* 25.6px */
            }
        }
    }

    .model-footer-container {
        margin: 12px 0 28px 0;

        .footer-btn-control {
            padding: 0 28px;

            .btn-group-control {
                display: flex;
                align-items: center;
                justify-content: center;

                .btn-sec {
                    display: flex;
                    flex: 1;
                    padding: 22px 28px;
                    justify-content: center;
                    align-items: center;
                    gap: 16px;
                    cursor: pointer;

                    .btn-text {
                        color: rgba(0, 0, 0, 0.8);
                        font-family: "PingFang SC";
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 16px;
                    }
                }

                .btn-plain {
                    background-color: rgba(0, 0, 0, 0.8);

                    .btn-text {
                        color: #f7fafd;
                    }
                }

                .btn-default {
                    border: 1px solid rgba(0, 0, 0, 0.8);
                }

                .mr16 {
                    margin-right: 16px;
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) and (min-width: 744px) {
}
@media screen and (max-width: 744px) {
    .prompt-model-container {
        width: 100%;
        min-width: unset;
        margin: 0 12px;
        border-radius: 8px;

        .model-mainer-container {
            padding: 20px 12px;
        }

        .model-footer-container {
            margin: 12px 0 20px 0;

            .footer-btn-control {
                padding: 0 12px;

                .btn-group-control {
                    .btn-sec {
                        padding: 18px 24px;
                        gap: 12px;
                    }
                }
            }
        }
    }
}
</style>
