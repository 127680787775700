/**
 * login  api
 */
import { UserRole } from "@/constant";
import type { UserDto } from "@/types";
import httpRequest from "@/utils/request";

const loginDoctor = (param: UserDto) => {
    return httpRequest({
        url: "/login/doctor",
        method: "post",
        data: param,
    });
};

const resetPassword = (param: {
    confirmPassword?: string;
    email: string;
    newPassword?: string;
}) => {
    return httpRequest({
        url: "/login/forgot-password",
        method: "post",
        data: param,
    });
};

const loginUser = (param: UserDto) => {
    return httpRequest({
        url: "/login/user",
        method: "post",
        data: param,
    });
};

const loginOtp = (param: {
    mobile: string;
    otp: string;
    userRole: UserRole;
}) => {
    return httpRequest({
        url: "/login/validate-otp",
        method: "post",
        data: param,
    });
};

const loginSocial = (param?: any) => {
    return httpRequest({
        url: "/login/social",
        method: "post",
        data: param,
    });
};

export default {
    loginDoctor,
    resetPassword,
    loginUser,
    loginOtp,
    loginSocial,
};
