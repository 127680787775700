<template>
    <div class="login-register-modal">
        <div
            v-if="createAccountStep !== 5 && createAccountStep !== 6"
            class="logo-cont"
        >
            <img src="@/assets/images/layout/app_logo.png" />
            <div>{{ loginAndRegisterTitle }}</div>
        </div>

        <!-- 登陆信息容器 -->
        <div v-if="showMessageCont === 'login'" class="login-container">
            <LoginMain
                v-model:currentStep="currentLoginStep"
                @on-close="closeLoginAndRegisterModal"
                @on-register="_handleShowRegister"
                @on-forgot-password="_handleEditPassword"
            ></LoginMain>
        </div>

        <!-- 注册信息容器 -->
        <div v-if="showMessageCont === 'register'" class="register-container">
            <RegisterMain
                v-model:currentStep="createAccountStep"
                :cur-base-info-step="curCompletePersonalInformationStep"
                @on-login="_handleShowLogin"
                @on-changeuser-info-step="onChangeCurUserInfoStep"
                @on-close="closeLoginAndRegisterModal"
            ></RegisterMain>
        </div>

        <!-- 忘记密码容器 -->
        <div
            v-if="showMessageCont === 'forgetPassword'"
            class="forget-password-container"
        >
            <ForgetPasswordMain
                v-model:currentStep="forgetPasswordStep"
                @on-login="_handleShowLogin"
            ></ForgetPasswordMain>
        </div>

        <!-- 上一步按钮-注册 -->
        <div
            v-if="showMessageCont === 'register' && createAccountStep !== 5"
            class="prev-step-cont"
            @click="prevRegisterStep"
        >
            <img src="@/assets/images/layout/prev_step_icon.png" />
        </div>

        <!-- 上一步按钮-忘记密码 -->
        <div
            v-if="showMessageCont === 'forgetPassword'"
            class="prev-step-cont"
            @click="prevForgetPasswordStep"
        >
            <img src="@/assets/images/layout/prev_step_icon.png" />
        </div>

        <!-- 关闭按钮 -->
        <div class="close-cont" @click="closeLoginAndRegisterModal">
            <img src="@/assets/images/layout/close_icon.png" />
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted, nextTick } from "vue";
import LoginMain from "./setModules/LoginMain.vue";
import RegisterMain from "./setModules/RegisterMain.vue";
import ForgetPasswordMain from "./setModules/ForgetPasswordMain.vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const emits = defineEmits(["closeLoginAndRegisterModal"]);
const store = useStore();
const router = useRouter();

const showMessageCont = ref<string>("login");
const currentLoginStep = ref<number>(1);
const createAccountStep = ref<number>(1);
const forgetPasswordStep = ref<number>(1);
const curCompletePersonalInformationStep = ref<number>(1);

// 弹窗标题
const loginAndRegisterTitle = computed(() => {
    let res = "Log in";
    switch (showMessageCont.value) {
        case "login":
            res =
                currentLoginStep.value === 1 ? "Join Charm as a..." : "Log in";
            break;
        case "register":
            if (createAccountStep.value === 2) {
                res = "Verify your identity";
            } else if (createAccountStep.value === 4) {
                res =
                    curCompletePersonalInformationStep.value === 1
                        ? "Complete personal information"
                        : "Your Interests";
            } else {
                res = "Create Account";
            }
            break;
        case "forgetPassword":
            res =
                forgetPasswordStep.value === 1 ||
                forgetPasswordStep.value === 2 ||
                forgetPasswordStep.value === 3
                    ? "Forgotten Password？"
                    : "Charm";
            break;
        default:
            break;
    }
    return res;
});

const onChangeCurUserInfoStep = (step: number) => {
    curCompletePersonalInformationStep.value = step;
};

const _handleShowLogin = () => {
    showMessageCont.value = "login";
};

const _goToPageDoctorProfile = () => {
    router.push({ path: "/user/profile", query: { from: "user" } });
};

const _handleShowRegister = async (type?: string) => {
    if (type === "thirdlogin") {
        const cacheUserThirdType = store.getters["user/getUserThirdType"];
        if (cacheUserThirdType === "0") {
            showMessageCont.value = "register";
            createAccountStep.value = 3;
        } else {
            await store.dispatch("user/fetchUserInfo");
            _goToPageDoctorProfile();
            nextTick(() => {
                closeLoginAndRegisterModal();
            });
        }
    } else {
        showMessageCont.value = "register";
    }
};

// 忘记密码
const _handleEditPassword = () => {
    showMessageCont.value = "forgetPassword";
    forgetPasswordStep.value = 1;
};

// 注册时后退一步
const prevRegisterStep = () => {
    if (createAccountStep.value === 1) {
        showMessageCont.value = "login";
    } else if (createAccountStep.value === 6) {
        createAccountStep.value = 4;
    } else {
        if (
            createAccountStep.value === 4 &&
            curCompletePersonalInformationStep.value === 2
        ) {
            curCompletePersonalInformationStep.value = 1;
        } else {
            createAccountStep.value--;
        }
    }
};

// 忘记密码时后退一步
const prevForgetPasswordStep = () => {
    if (forgetPasswordStep.value === 1) {
        showMessageCont.value = "login";
    } else if (forgetPasswordStep.value === 4) {
        forgetPasswordStep.value -= 2;
    } else {
        forgetPasswordStep.value--;
    }
};

// 关闭登陆注册弹框
const closeLoginAndRegisterModal = () => {
    emits("closeLoginAndRegisterModal");
    showMessageCont.value = "login";
    createAccountStep.value = 1;
};

const _initUserLoginInfo = () => {
    //清理缓存 undo
    //清理用户信息 undo
};

onMounted(() => {
    nextTick(() => {
        _initUserLoginInfo();
    });
});
</script>

<style lang="scss" scoped>
.login-register-modal {
    position: relative;
    width: 620px;
    height: 686px;
    padding: 40px 70px 0;
    box-sizing: border-box;
    background-color: #fff;

    .logo-cont {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
            width: 38px;
            height: 40px;
        }

        div {
            color: #000;
            text-align: center;
            font-family: "Open Sans";
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    }

    .prev-step-cont {
        position: absolute;
        top: 30px;
        left: 26px;
        cursor: pointer;

        img {
            width: 40px;
            height: 20px;
        }
    }

    .close-cont {
        position: absolute;
        top: 30px;
        right: 30px;
        cursor: pointer;

        img {
            width: 20px;
            height: 20px;
        }
    }
}
@media screen and (max-width: 1024px) and (min-width: 744px) {
}
@media screen and (max-width: 744px) {
    .login-register-modal {
        position: relative;
        width: 100%;
        height: 560px;
        margin: 0 12px;
        padding: 20px 12px;
        border-radius: 8px;
        overflow-y: auto;

        .logo-cont {
            img {
                width: 30px;
                height: 30px;
            }

            div {
                font-size: 20px;
            }
        }

        .prev-step-cont {
            position: absolute;
            top: 20px;
            left: 20px;

            img {
                width: 30px;
                height: 15px;
            }
        }

        .close-cont {
            position: absolute;
            top: 20px;
            right: 20px;

            img {
                width: 20px;
                height: 20px;
            }
        }
    }
}
</style>
