<template>
    <div class="date-select-form-item">
        <n-select
            placeholder="MM"
            v-model:value="selectedMonth"
            :options="monthList"
            @update:value="onChangeDate"
        />
        <n-select
            placeholder="DD"
            v-model:value="selectedDay"
            :options="daysInMonth"
            @update:value="onChangeDate"
        />
        <n-select
            placeholder="YYYY"
            v-model:value="selectedYear"
            :options="yearList"
            @update:value="onChangeDate"
        />
    </div>
</template>

<script lang="ts" setup>
import { ref, watch, computed } from "vue";
import { NSelect } from "naive-ui";
import dayjs from "dayjs";

const props = defineProps({
    value: {
        type: String,
        default: () => {
            return "";
        },
    },
});

const emits = defineEmits(["onChange"]);
// const selectedYear = ref(new Date().getFullYear());
// const selectedMonth = ref(new Date().getMonth() + 1);
// const selectedDay = ref(new Date().getDate());
const selectedYear = ref<number | null>(null);
const selectedMonth = ref<number | null>(null);
const selectedDay = ref<number | null>(null);

const yearList = computed(() => {
    const currentYear = new Date().getFullYear();
    const allYearList = Array.from({ length: 150 }, (_, i) => {
        return {
            value: currentYear - i,
            label: `${currentYear - i}`,
        };
    });
    return allYearList;
});

const monthList = computed(() => {
    const months = 12;
    const allYearList = Array.from({ length: months }, (_, i) => {
        return {
            value: i + 1,
            label: `${i + 1}`,
        };
    });
    return allYearList;
});

const daysInMonth = computed(() => {
    const _selectedYear = selectedYear.value
        ? selectedYear.value
        : new Date().getFullYear();
    const _selectedMonth = selectedMonth.value
        ? selectedMonth.value
        : new Date().getMonth() + 1;
    const days = new Date(_selectedYear, _selectedMonth, 0).getDate();
    return Array.from({ length: days }, (_, i) => {
        return {
            value: i + 1,
            label: `${i + 1}`,
        };
    });
});

const selectDate = computed(() => {
    const currentYear = new Date().getFullYear();
    return {
        month: selectedMonth.value || 1,
        day: selectedDay.value || 1,
        year: selectedYear.value || currentYear,
    };
});

watch(
    () => props.value,
    (newV) => {
        if (newV) {
            selectedYear.value = dayjs(newV).get("year");
            selectedMonth.value = dayjs(newV).get("month") + 1;
            selectedDay.value = dayjs(newV).get("date");
        }
    },
    { immediate: true }
);

// watch([selectedYear, selectedMonth], () => {
//     selectedDay.value = selectedDay.value
//         ? Math.min(
//               selectedDay.value,
//               daysInMonth.value[daysInMonth.value.length - 1].value
//           )
//         : null;
// });

// watch(
//     () => selectDate.value,
//     (newV, oldV) => {
//         //emits("onChange", newV);
//     },
//     { immediate: true, deep: true }
// );

const onChangeDate = () => {
    emits("onChange", selectDate.value);
};
</script>

<style lang="scss" scoped>
.date-select-form-item {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    gap: 26px;
}
@media screen and (max-width: 744px) {
    .date-select-form-item {
        gap: 12px;
    }
}
</style>
