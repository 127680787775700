import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "date-select-form-item" }

import { ref, watch, computed } from "vue";
import { NSelect } from "naive-ui";
import dayjs from "dayjs";


export default /*@__PURE__*/_defineComponent({
  __name: 'DateSelect',
  props: {
    value: {
        type: String,
        default: () => {
            return "";
        },
    },
},
  emits: ["onChange"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emits = __emit;
// const selectedYear = ref(new Date().getFullYear());
// const selectedMonth = ref(new Date().getMonth() + 1);
// const selectedDay = ref(new Date().getDate());
const selectedYear = ref<number | null>(null);
const selectedMonth = ref<number | null>(null);
const selectedDay = ref<number | null>(null);

const yearList = computed(() => {
    const currentYear = new Date().getFullYear();
    const allYearList = Array.from({ length: 150 }, (_, i) => {
        return {
            value: currentYear - i,
            label: `${currentYear - i}`,
        };
    });
    return allYearList;
});

const monthList = computed(() => {
    const months = 12;
    const allYearList = Array.from({ length: months }, (_, i) => {
        return {
            value: i + 1,
            label: `${i + 1}`,
        };
    });
    return allYearList;
});

const daysInMonth = computed(() => {
    const _selectedYear = selectedYear.value
        ? selectedYear.value
        : new Date().getFullYear();
    const _selectedMonth = selectedMonth.value
        ? selectedMonth.value
        : new Date().getMonth() + 1;
    const days = new Date(_selectedYear, _selectedMonth, 0).getDate();
    return Array.from({ length: days }, (_, i) => {
        return {
            value: i + 1,
            label: `${i + 1}`,
        };
    });
});

const selectDate = computed(() => {
    const currentYear = new Date().getFullYear();
    return {
        month: selectedMonth.value || 1,
        day: selectedDay.value || 1,
        year: selectedYear.value || currentYear,
    };
});

watch(
    () => props.value,
    (newV) => {
        if (newV) {
            selectedYear.value = dayjs(newV).get("year");
            selectedMonth.value = dayjs(newV).get("month") + 1;
            selectedDay.value = dayjs(newV).get("date");
        }
    },
    { immediate: true }
);

// watch([selectedYear, selectedMonth], () => {
//     selectedDay.value = selectedDay.value
//         ? Math.min(
//               selectedDay.value,
//               daysInMonth.value[daysInMonth.value.length - 1].value
//           )
//         : null;
// });

// watch(
//     () => selectDate.value,
//     (newV, oldV) => {
//         //emits("onChange", newV);
//     },
//     { immediate: true, deep: true }
// );

const onChangeDate = () => {
    emits("onChange", selectDate.value);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(NSelect), {
      placeholder: "MM",
      value: selectedMonth.value,
      "onUpdate:value": [
        _cache[0] || (_cache[0] = ($event: any) => ((selectedMonth).value = $event)),
        onChangeDate
      ],
      options: monthList.value
    }, null, 8, ["value", "options"]),
    _createVNode(_unref(NSelect), {
      placeholder: "DD",
      value: selectedDay.value,
      "onUpdate:value": [
        _cache[1] || (_cache[1] = ($event: any) => ((selectedDay).value = $event)),
        onChangeDate
      ],
      options: daysInMonth.value
    }, null, 8, ["value", "options"]),
    _createVNode(_unref(NSelect), {
      placeholder: "YYYY",
      value: selectedYear.value,
      "onUpdate:value": [
        _cache[2] || (_cache[2] = ($event: any) => ((selectedYear).value = $event)),
        onChangeDate
      ],
      options: yearList.value
    }, null, 8, ["value", "options"])
  ]))
}
}

})