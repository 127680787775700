<template>
    <div class="home-index">
        <!-- 顶部描述 -->
        <div class="charm-message-cont section-message-cont">
            <!-- <div class="img-cont"></div> -->

            <n-carousel autoplay :interval="5000" :show-dots="false">
                <div class="img-cont img-cont1"></div>
                <div class="img-cont img-cont2"></div>
            </n-carousel>

            <div class="loading-bar-cont">
                <div class="loading-bar"></div>
            </div>

            <div class="message-cont">
                <div class="message-title">
                    Charm Community For All Beauty Lovers
                </div>
                <div class="message-describe">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </div>
            </div>

            <div class="btn-cont">
                <n-button color="#000000" @click="_handleShowCharmVideo">
                    <template #icon>
                        <img
                            style="width: 32px; height: 32px"
                            src="@/assets/images/home/video_play_icon.png"
                        />
                    </template>
                    What is Charm?
                </n-button>
            </div>
        </div>

        <!-- 医生描述 -->
        <div class="doctor-message-cont section-message-cont">
            <div class="img-cont" :style="calcDoctorImgContHeightObj">
                <div class="img-item">
                    <div class="img-item-message">
                        <span>Ut enim ad min</span>
                    </div>
                </div>
                <div class="img-item">
                    <div class="img-item-message">
                        <span>Ut enim ad min</span>
                    </div>
                </div>
                <div class="img-item">
                    <div class="img-item-message">
                        <span>Ut enim ad min</span>
                    </div>
                </div>
            </div>

            <div class="message-cont">
                <div class="message-title">Our Doctors</div>
                <div class="message-describe">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </div>
            </div>

            <div class="btn-cont">
                <n-button color="#000000" @click="_goToDoctorsHomePage">
                    Consult a doctor
                </n-button>
            </div>
        </div>

        <!-- 帖子描述 -->
        <div class="post-message-cont section-message-cont">
            <div class="img-cont"></div>

            <div class="message-cont">
                <div class="message-title">Share Your Posts</div>
                <div class="message-describe">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </div>
            </div>

            <div class="btn-cont">
                <n-button color="#000000" @click="_goToPostHomePage">
                    View More Posts
                </n-button>
            </div>
        </div>

        <!-- 其他手术项目 -->
        <div class="other-cosmetic-procedures section-message-cont">
            <div class="message-cont">
                <div class="message-title">Other Cosmetic Procedures</div>
            </div>

            <div class="cosmetic-procedures-cont">
                <div
                    v-for="(item, index) in otherCosmeticProceduresList"
                    :key="index"
                    class="cosmetic-procedures-item"
                >
                    <img :src="item.imgSrc" />
                    <div>{{ item.name }}</div>
                </div>
            </div>

            <div class="btn-cont">
                <n-button color="#000000" @click="_goToProcedureHomePage">
                    View All Procedures
                </n-button>
            </div>
        </div>

        <!-- 下载信息 -->
        <div class="download-message-cont section-message-cont">
            <div class="phone-cont" @click="_handleShowCharmH5Video">
                <n-icon class="btn-icon" size="60">
                    <img src="@/assets/icons/icon_video_start_play.svg" />
                </n-icon>
            </div>

            <div class="qrcode-cont">
                <div class="title">Try Charm</div>
                <div class="tip">Scan to download</div>
                <img src="@/assets/images/home/download_scan_qrcode_img.png" />
            </div>
        </div>

        <!--preview video-->
        <VideoPreviewModal
            v-model:show="showVideoPreviewModal"
            :options="curVideoPreivewOpts"
            to=".home-index"
        ></VideoPreviewModal>
    </div>
</template>

<script lang="ts" setup>
import { NButton, NCarousel, NIcon } from "naive-ui";
import { computed, nextTick, onMounted, onUnmounted, ref } from "vue";
import { ProcedureTransMockList } from "@/mock/MockList";
import VideoPreviewModal from "@/components/VideoPreviewModal.vue";
import { useRouter } from "vue-router";
import videoUrl from "@/assets/images/home/App-Demo-V10.mp4";
import videoH5Url from "@/assets/images/home/App-Demo-H5.mp4";

const router = useRouter();
const otherCosmeticProceduresList = ref<any>(ProcedureTransMockList);
const screenMinWidth = ref<number>(1440);
const calcDoctorImgContWidth = ref<number>(480);
const showVideoPreviewModal = ref<boolean>(false);
const videoPreviewOpts = {
    showEdit: false,
    src: videoUrl,
};
const videoH5PreviewOpts = {
    showEdit: false,
    src: videoH5Url,
};
const curVideoPreivewOpts = ref(videoPreviewOpts);

const calcDoctorImgContHeightObj = computed(() => {
    return {
        height: (calcDoctorImgContWidth.value * 619) / 480 + "px",
    };
});

// 计算屏幕大小
const calcScreenMinWidth = (vWidth: number) => {
    if (vWidth >= 1440) {
        screenMinWidth.value = 1440;
    }
    if (1020 <= vWidth && vWidth < 1440) {
        screenMinWidth.value = 1020;
    }
    if (vWidth < 1020) {
        screenMinWidth.value = 744;
    }
};

const _onShowVideoPreviewModal = () => {
    showVideoPreviewModal.value = !showVideoPreviewModal.value;
};

const _handleShowCharmVideo = () => {
    curVideoPreivewOpts.value = videoPreviewOpts;
    nextTick(() => {
        _onShowVideoPreviewModal();
    });
};

const _handleShowCharmH5Video = () => {
    curVideoPreivewOpts.value = videoH5PreviewOpts;
    nextTick(() => {
        _onShowVideoPreviewModal();
    });
};

const _goToDoctorsHomePage = () => {
    router.push({
        path: "/doctor",
    });
};

const _goToPostHomePage = () => {
    router.push({
        path: "/post",
    });
};

const _goToProcedureHomePage = () => {
    router.push({
        path: "/procedure",
    });
};

onMounted(() => {
    calcScreenMinWidth(window.innerWidth);

    calcDoctorImgContWidth.value = window.innerWidth / 3;

    window.addEventListener("resize", () => {
        calcScreenMinWidth(window.innerWidth);

        calcDoctorImgContWidth.value = window.innerWidth / 3;
    });
});

onUnmounted(() => {
    window.removeEventListener("resize", () => {
        calcScreenMinWidth(window.innerWidth);
    });
});
</script>

<style lang="scss" scoped>
.home-index {
    background-color: #ebfbff;

    .section-message-cont {
        .message-cont {
            .message-title {
                font-size: 50px;
            }
        }
    }
    .charm-message-cont {
        padding-top: 14px;

        .img-cont {
            width: 100%;
            height: 590px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
        }

        .img-cont1 {
            background-image: url("@/assets/images/home/charm_message_img.png");
        }

        .img-cont2 {
            background-image: url("@/assets/images/home/charm_message_img.png");
        }

        .loading-bar-cont {
            width: 100%;
            height: 2px;
            background-color: rgba(0, 0, 0, 0.37);

            .loading-bar {
                width: 0;
                height: 2px;
                background: linear-gradient(93deg, #8ef 0%, #ff7de7 100%);
                animation: loading-bar-animation 5s ease-in-out infinite;
            }
        }

        @keyframes loading-bar-animation {
            0% {
                width: 0;
            }
            100% {
                width: 100%;
            }
        }

        .message-cont {
            margin-top: 50px;

            .message-title {
                margin: 0 auto;
                width: 71.25%;
                color: #000;
                text-align: center;
                font-family: "Playfair Display";
                font-style: normal;
                font-weight: 600;
                line-height: 140%;
            }

            .message-describe {
                margin: 50px auto 0;
                width: 71.25%;
                color: #363636;
                text-align: center;
                font-family: "Abhaya Libre Regular";
                font-size: 22px;
                font-style: normal;
                font-weight: 400;
                line-height: 135%;
            }
        }

        .btn-cont {
            margin-top: 50px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            :deep(.n-button) {
                width: 232px;
                height: 60px;
                font-size: 18px;
                font-weight: 600;
            }

            :deep(.n-button__content) {
                margin-left: 10px;
            }
        }
    }

    .doctor-message-cont {
        margin-top: 100px;

        .img-cont {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            .img-item {
                flex: 1;
                position: relative;
                height: 100%;
                background-size: cover;
                overflow: hidden;

                &:nth-child(1) {
                    background-image: url("@/assets/images/home/doctor_message1.png");
                }
                &:nth-child(2) {
                    background-image: url("@/assets/images/home/doctor_message2.png");
                }
                &:nth-child(3) {
                    background-image: url("@/assets/images/home/doctor_message3.png");
                }

                &:hover .img-item-message {
                    bottom: 0;
                }

                .img-item-message {
                    position: absolute;
                    left: 0;
                    bottom: -18.26%;
                    width: 100%;
                    height: 18.26%;
                    background: radial-gradient(
                        50% 50% at 50% 50%,
                        rgba(255, 255, 255, 0.3) 32%,
                        rgba(255, 255, 255, 0.5) 100%
                    );
                    transition: bottom 0.5s ease-out;

                    span {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        color: #363636;
                        font-family: "Abhaya Libre Regular";
                        font-size: 22px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 135%;
                        white-space: nowrap;
                    }
                }
            }
        }

        .message-cont {
            margin-top: 50px;

            .message-title {
                margin: 0 auto;
                width: 71.25%;
                color: #000;
                text-align: center;
                font-family: "Playfair Display";
                font-style: normal;
                font-weight: 600;
                line-height: 140%;
            }

            .message-describe {
                margin: 50px auto 0;
                width: 71.25%;
                color: #363636;
                text-align: center;
                font-family: "Abhaya Libre Regular";
                font-size: 22px;
                font-style: normal;
                font-weight: 400;
                line-height: 135%;
            }
        }

        .btn-cont {
            margin-top: 50px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            :deep(.n-button) {
                width: 232px;
                height: 60px;
                font-size: 18px;
                font-weight: 600;
            }
        }
    }

    .post-message-cont {
        margin-top: 100px;

        .img-cont {
            width: 100%;
            height: 872px;
            background-image: url("@/assets/images/home/post_message_img.png");
            background-size: cover;
        }

        .message-cont {
            margin-top: 50px;

            .message-title {
                margin: 0 auto;
                width: 71.25%;
                color: #000;
                text-align: center;
                font-family: "Playfair Display";
                font-size: 50px;
                font-style: normal;
                font-weight: 600;
                line-height: 140%;
            }

            .message-describe {
                margin: 50px auto 0;
                width: 83.33%;
                color: #363636;
                text-align: center;
                font-family: "Abhaya Libre Regular";
                font-size: 22px;
                font-style: normal;
                font-weight: 400;
                line-height: 135%;
            }
        }

        .btn-cont {
            margin-top: 50px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            :deep(.n-button) {
                width: 232px;
                height: 60px;
                font-size: 18px;
                font-weight: 600;
            }
        }
    }

    .other-cosmetic-procedures {
        margin-top: 100px;
        padding-top: 50px;
        padding-bottom: 100px;
        background-color: #131313;

        .message-cont {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .message-title {
                color: #e6b3b3;
                font-family: "Playfair Display";
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
            }
        }

        .cosmetic-procedures-cont {
            margin-top: 60px;
            padding: 0 80px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            box-sizing: border-box;

            .cosmetic-procedures-item {
                margin-top: 20px;
                width: 320px;
                height: 200px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                &:hover {
                    img {
                        opacity: 1;
                    }

                    div {
                        color: rgba(255, 255, 255, 1);
                    }
                }

                img {
                    width: 140px;
                    height: 140px;
                    opacity: 0.8;
                }

                div {
                    color: rgba(255, 255, 255, 0.3);
                    text-align: center;
                    font-family: Alata;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }

        .btn-cont {
            margin-top: 80px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            :deep(.n-button) {
                width: 232px;
                height: 60px;
                font-family: "Open Sans";
                font-size: 18px;
                font-weight: 600;
                border: 1px solid;
                border-image: linear-gradient(to right, #88eeff, #ff7de7) 1;
            }
        }
    }

    .download-message-cont {
        padding-top: 50px;
        padding-bottom: 100px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: #fff;

        .phone-cont {
            position: relative;
            width: 300px;
            height: 557px;
            background-image: url("@/assets/images/home/download_phone_img.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;

            .btn-icon {
                position: absolute;
                top: 50%;
                left: 50%;
                margin-top: -30px;
                margin-left: -30px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .qrcode-cont {
            margin-left: 50px;

            .title {
                padding-left: 9px;
                color: #000;
                font-family: "Playfair Display";
                font-size: 40px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
            }

            .tip {
                margin-top: 21px;
                padding-left: 9px;
                color: #363636;
                font-family: "Abhaya Libre Regular";
                font-size: 22px;
                font-style: normal;
                font-weight: 400;
                line-height: 135%;
            }

            img {
                margin-top: 11px;
                width: 235px;
                height: 235px;
            }
        }
    }
}

@media screen and (max-width: 1024px) and (min-width: 744px) {
    .home-index {
        .other-cosmetic-procedures {
            .cosmetic-procedures-cont {
                .cosmetic-procedures-item {
                    width: 180px;
                }
            }
        }
    }
}

@media screen and (max-width: 744px) {
    .home-index {
        .section-message-cont {
            .message-cont {
                .message-title {
                    width: 100%;
                    font-size: 24px;
                }
                .message-describe {
                    width: 94%;
                    margin-top: 20px;
                    font-size: 20px;
                }
            }
        }
        .other-cosmetic-procedures {
            .message-cont {
                .message-title {
                    width: 100%;
                    text-align: center;
                }
            }
            .cosmetic-procedures-cont {
                margin-top: 20px;
                padding: 0 20px;
                .cosmetic-procedures-item {
                    width: 140px;

                    > div {
                        font-size: 18px;
                    }
                }
            }
        }
        .download-message-cont {
            flex-direction: column;
            .qrcode-cont {
                margin-left: 0;
                text-align: center;
            }
        }
    }
}
</style>
