<template>
    <div class="register-message-cont">
        <!-- 输入邮箱--step1 -->
        <div v-if="createAccountStep === 1" class="email-address-input-cont">
            <n-form
                ref="registerEmailFormRef"
                :model="registerEmail"
                :rules="registerEmailRules"
            >
                <n-form-item
                    path="email"
                    label="Email Address"
                    :theme-overrides="formItemThemeOverrides"
                >
                    <n-input
                        v-model:value="registerEmail.email"
                        placeholder="Enter your Email Address"
                        size="large"
                        @keydown.enter.prevent
                    >
                        <template #suffix>
                            <n-icon
                                v-show="showRegisterEmailClearBtn"
                                class="btn-icon"
                                size="16"
                                @click="_handleClearRegisterEmail"
                            >
                                <img
                                    src="@/assets/icons/icon_input_clear_plain.svg"
                                />
                            </n-icon>
                        </template>
                    </n-input>
                </n-form-item>
            </n-form>

            <div class="next-btn-cont">
                <n-button
                    :color="
                        !isDisabledRegisterEmailBtn
                            ? '#000000'
                            : 'rgba(0, 0, 0, 0.2)'
                    "
                    :text-color="
                        !isDisabledRegisterEmailBtn ? '#88EEFF' : '#ffffff'
                    "
                    icon-placement="right"
                    :disabled="isDisabledRegisterEmailBtn"
                    @click="_handleValidEmail"
                >
                    <template v-if="isDisabledRegisterEmailBtn">
                        Next
                    </template>
                    <template v-else> Verify </template>
                    <template #icon>
                        <img
                            style="margin-left: 12px; width: 26px; height: 14px"
                            src="@/assets/images/layout/next_icon.png"
                        />
                    </template>
                </n-button>
            </div>

            <div class="log-in-tip">
                Already have an account?
                <span @click="_handleShowLogin">Log in</span>
            </div>
        </div>

        <!-- 等待验证身份--step2 -->
        <div v-if="createAccountStep === 2" class="verify-identity-cont">
            <div class="has-sended-message">
                We have sent you an email to verify that your email address is
                <span>marketing@charm-life.com</span>
            </div>

            <div class="btn-group">
                <div class="custom-btn resend-btn" @click="_hanleResendEmail">
                    Resend Email
                </div>
                <div class="custom-btn check-btn" @click="_hanleCheckVerify">
                    Check Verify
                </div>
            </div>
        </div>

        <!-- 输入注册账号的昵称及密码--step3 -->
        <div v-if="createAccountStep === 3" class="account-message-input-cont">
            <n-form
                ref="registerFormRef"
                :model="registerMessageModel"
                :rules="registerMessageModelRules"
            >
                <n-form-item
                    path="nickname"
                    label="User Name"
                    :theme-overrides="formItemThemeOverrides"
                >
                    <n-input
                        v-model:value="registerMessageModel.nickname"
                        placeholder="Enter Your User Name"
                        size="large"
                        @keydown.enter.prevent
                    >
                        <template #suffix>
                            <n-icon
                                v-show="showRegisterUserNameClearBtn"
                                class="btn-icon"
                                size="16"
                                @click="_handleClearRegisterUserName"
                            >
                                <img
                                    src="@/assets/icons/icon_input_clear_plain.svg"
                                />
                            </n-icon>
                        </template>
                    </n-input>
                </n-form-item>
                <n-form-item
                    path="password"
                    label="Password"
                    :theme-overrides="formItemThemeOverrides"
                >
                    <n-input
                        v-model:value="registerMessageModel.password"
                        :type="showRegisterPassword ? 'text' : 'password'"
                        placeholder="Enter Your Password"
                        size="large"
                        @keydown.enter.prevent
                    >
                        <template #suffix>
                            <n-icon
                                class="btn-icon"
                                size="24"
                                @click="_handleShowRegisterPassword"
                            >
                                <img
                                    v-if="showRegisterPassword"
                                    src="@/assets/icons/icon_layout_eye.svg"
                                />
                                <img
                                    v-else
                                    src="@/assets/icons/icon_layout_password.svg"
                                />
                            </n-icon>
                            <n-icon
                                v-show="showRegisterPasswordClearBtn"
                                class="btn-icon"
                                size="16"
                                @click="_handleClearRegisterPassword"
                            >
                                <img
                                    src="@/assets/icons/icon_input_clear_plain.svg"
                                />
                            </n-icon>
                        </template>
                    </n-input>
                </n-form-item>
                <n-form-item
                    path="confirmPassword"
                    label="Re-enter your Password"
                    :theme-overrides="formItemThemeOverrides"
                >
                    <n-input
                        v-model:value="registerMessageModel.confirmPassword"
                        :type="
                            showRegisterConfirmPassword ? 'text' : 'password'
                        "
                        placeholder="Confirm Your Password"
                        size="large"
                        @keydown.enter.prevent
                    >
                        <template #suffix>
                            <n-icon
                                class="btn-icon"
                                size="24"
                                @click="_handleShowRegisterConfirmPassword"
                            >
                                <img
                                    v-if="showRegisterConfirmPassword"
                                    src="@/assets/icons/icon_layout_eye.svg"
                                />
                                <img
                                    v-else
                                    src="@/assets/icons/icon_layout_password.svg"
                                />
                            </n-icon>
                            <n-icon
                                v-show="showRegisterConfirmPasswordClearBtn"
                                class="btn-icon"
                                size="16"
                                @click="_handleClearRegisterConfirmPassword"
                            >
                                <img
                                    src="@/assets/icons/icon_input_clear_plain.svg"
                                />
                            </n-icon>
                        </template>
                    </n-input>
                </n-form-item>
            </n-form>

            <div class="create-account-btn-cont">
                <n-button
                    :color="
                        !isDisabledCreateAccount
                            ? '#000000'
                            : 'rgba(0, 0, 0, 0.2)'
                    "
                    :text-color="
                        !isDisabledCreateAccount ? '#88EEFF' : '#ffffff'
                    "
                    :disabled="isDisabledCreateAccount"
                    @click="_handleCreateAccount"
                    >Create</n-button
                >
            </div>
        </div>

        <!-- 选择职业--step4 -->
        <!-- <div v-if="createAccountStep === 4" class="choose-career">
            <ChoseUserIdentify
                :cur-role-type="cacheUserRole"
                @on-next="_handleChooseCareerNext"
            ></ChoseUserIdentify>
        </div> -->

        <!-- 完善个人信息--step5 -->
        <div
            v-if="createAccountStep === 4"
            class="complete-personal-information"
        >
            <n-form
                v-show="curCompletePersonalInformationStep === 1"
                ref="completePersonalInformationFormRef"
                :model="completePersonalInformationModel"
                :rules="completePersonalInformationModelRules"
            >
                <n-form-item :theme-overrides="formItemThemeOverrides">
                    <n-radio-group
                        v-model:value="completePersonalInformationModel.gender"
                        name="radiogroup1"
                    >
                        <n-radio
                            v-for="rad in genderOtpsList"
                            :key="rad.value"
                            :value="rad.value"
                            size="large"
                            :theme-overrides="radioItemThemeOverrides"
                        >
                            {{ rad.label }}
                        </n-radio>
                    </n-radio-group>
                </n-form-item>
                <n-form-item
                    path="userName"
                    label="Your Name"
                    :theme-overrides="formItemThemeOverrides"
                >
                    <n-input
                        v-model:value="
                            completePersonalInformationModel.userName
                        "
                        placeholder="Enter Your Name"
                        size="large"
                        @keydown.enter.prevent
                    />
                </n-form-item>
                <n-form-item
                    path="birthday"
                    label="Birthday"
                    :theme-overrides="formItemThemeOverrides"
                >
                    <DateSelect
                        :value="completePersonalInformationModel.birthday"
                        @on-change="onSelectBirthady"
                    ></DateSelect>
                </n-form-item>
            </n-form>

            <div
                v-show="curCompletePersonalInformationStep === 2"
                class="personal-preference-procedure"
            >
                <div class="refresh-procedure-tip">
                    Don’t like these?
                    <span
                        class="btn-refresh"
                        @click="_handleRefreshInterestedList"
                    >
                        Refresh
                    </span>
                </div>

                <div class="procedure-list-cont">
                    <n-scrollbar style="max-height: 300px">
                        <div class="procedure-list-wrapper">
                            <div
                                v-for="(item, index) in curInterestedList"
                                :key="index"
                                class="procedure-list-item"
                                :class="{
                                    selected:
                                        completePersonalInformationModel.procedureSelected.includes(
                                            item?.id
                                        ),
                                }"
                                @click="_hancleSelcetProcedureItem(item)"
                            >
                                <div class="img-cont">
                                    <img :src="item.img" />
                                </div>
                                <div class="procedure-text">
                                    {{ item?.name }}
                                </div>
                            </div>
                        </div>
                    </n-scrollbar>
                </div>
            </div>

            <div class="btn-cont">
                <n-button
                    text-color="#000000"
                    style="border: 1px solid #000000"
                    @click="skipCompletePersonalInformation"
                >
                    Skip
                </n-button>
                <n-button
                    :color="
                        !isDisabledUserMemberPublish
                            ? '#000000'
                            : 'rgba(0, 0, 0, 0.2)'
                    "
                    :text-color="
                        !isDisabledUserMemberPublish ? '#88EEFF' : '#ffffff'
                    "
                    :disabled="isDisabledUserMemberPublish"
                    @click="_handlePublish"
                >
                    Publish
                </n-button>
            </div>

            <div
                class="complete-personal-information-line"
                :class="{
                    'two-complete-personal-information':
                        curCompletePersonalInformationStep === 2,
                }"
            >
                <div class="line">
                    <div class="weight-line"></div>
                </div>
                <div class="text">
                    {{ curCompletePersonalInformationStep }} of 2
                </div>
            </div>
        </div>

        <!-- 注册完成--step6 | step7 -->
        <div
            v-if="createAccountStep === 5 || createAccountStep === 6"
            class="register-done"
        >
            <div class="title">Welcome to Charm Life</div>

            <div class="big-logo-cont">
                <img src="@/assets/images/layout/big_app_logo.png" />
            </div>

            <n-button
                v-if="createAccountStep === 5"
                color="#000000"
                text-color="#88EEFF"
                @click="_handleShowLogin"
            >
                Log in
            </n-button>
            <n-button
                v-if="createAccountStep === 6"
                color="#000000"
                text-color="#88EEFF"
                icon-placement="right"
                @click="_handleGoToMyProfilePage"
            >
                Let’s Set Up Your Profile
                <template #icon>
                    <img
                        style="margin-left: 12px; width: 26px; height: 14px"
                        src="@/assets/images/layout/next_icon.png"
                    />
                </template>
            </n-button>
        </div>
    </div>
</template>

<script lang="ts" setup>
import {
    NForm,
    NFormItem,
    NInput,
    NButton,
    NRadioGroup,
    NRadio,
    NIcon,
    NScrollbar,
} from "naive-ui";
import type { FormInst, FormItemRule, FormRules } from "naive-ui";
import { ref, computed, watch, onMounted, nextTick } from "vue";
import { MessageAlert } from "@/composables/useMessageAlert";
// import ChoseUserIdentify from "./ChoseUserIdentify.vue";
import {
    GenderTypeList,
    REQUEST_SCCUESS_CODE,
    USER_INDENTIFY_KEY,
    UserRole,
} from "@/constant";
import {
    validateEmail,
    validatePassword,
    validateUserName,
} from "@/utils/util";
import { useStore } from "vuex";
import type {
    FormItemThemeOverrides,
    RadioThemeOverrides,
} from "@/types/themeOverrides";
import DateSelect from "@/components/DateSelect.vue";
import { useRegisterAct } from "@/composables/useRegisterAct";
import { useInterestedList } from "@/composables/useInterestedList";
import storage from "@/utils/storage";
import { useRouter } from "vue-router";

const props = defineProps({
    currentStep: {
        type: Number,
        default: () => {
            return 1;
        },
    },
    curBaseInfoStep: {
        type: Number,
        default: () => {
            return 1;
        },
    },
});
const emits = defineEmits([
    "update:currentStep",
    "onLogin",
    "onChangeuserInfoStep",
    "onClose",
]);

const formItemThemeOverrides: FormItemThemeOverrides = {
    feedbackPadding: "12px 0",
};

const radioItemThemeOverrides: RadioThemeOverrides = {
    textColor: "rgba(0, 0, 0, 0.20)",
    buttonTextColor: "rgba(0, 0, 0, 0.80)",
    dotColorActive: "#2A8CFF",
};

const store = useStore();
const router = useRouter();
const {
    _clickVerifyEmailByApi,
    _userSetTheirProfileByApi,
    _isConfirmEmailByApi,
} = useRegisterAct();
const { curInterestedList, _initInterestedPage } = useInterestedList();
const registerEmailFormRef = ref<FormInst | null>(null);
const createAccountStep = ref<number>(1);
const genderOtpsList = ref(GenderTypeList);
const showRegisterPassword = ref<boolean>(false);
const showRegisterConfirmPassword = ref<boolean>(false);
const registerEmail = ref<any>({
    email: "",
});
const registerEmailRules: FormRules = {
    email: [
        {
            required: true,
            trigger: ["input"],
            validator: (rule: FormItemRule, value: any) => {
                //console.log(rule, value);
                if (!value) {
                    return new Error("Please input");
                } else if (!validateEmail(value)) {
                    return new Error("Please enter a valid email address");
                }
                return true;
            },
        },
    ],
};
const cacheUserRole = ref<string>("USER");

const showRegisterEmailClearBtn = computed(() => {
    return registerEmail.value.email ? true : false;
});

const isDisabledRegisterEmailBtn = computed(() => {
    return registerEmail.value.email ? false : true;
});

const registerFormRef = ref<FormInst | null>(null);
const registerMessageModel = ref<any>({
    nickname: null,
    password: null,
    confirmPassword: null,
});

const showRegisterUserNameClearBtn = computed(() => {
    return registerMessageModel.value.nickname ? true : false;
});

const showRegisterPasswordClearBtn = computed(() => {
    return registerMessageModel.value.password ? true : false;
});

const showRegisterConfirmPasswordClearBtn = computed(() => {
    return registerMessageModel.value.confirmPassword ? true : false;
});

const isDisabledCreateAccount = computed(() => {
    return registerMessageModel.value.nickname &&
        registerMessageModel.value.password &&
        registerMessageModel.value.confirmPassword
        ? false
        : true;
});

const registerMessageModelRules: FormRules = {
    nickname: [
        {
            required: true,
            trigger: ["input"],
            validator: (rule: FormItemRule, value: any) => {
                //console.log(rule, value);
                if (!value) {
                    return new Error("Please input");
                } else if (!validateUserName(value)) {
                    return new Error("user name must least 5~30 letters");
                }
                return true;
            },
        },
    ],
    password: [
        {
            required: true,
            trigger: ["input"],
            validator: (rule: FormItemRule, value: any) => {
                //console.log(rule, value);
                if (!value) {
                    return new Error("Please input");
                } else if (!validatePassword(value)) {
                    return new Error("password must 6~16 letters and numbers");
                }
                return true;
            },
        },
    ],
    confirmPassword: [
        {
            required: true,
            trigger: ["input"],
            validator: (rule: FormItemRule, value: any) => {
                //console.log(rule, value);
                if (!value) {
                    return new Error("Please input");
                } else if (!validatePassword(value)) {
                    return new Error("password must 6~16 letters and numbers");
                }
                return true;
            },
        },
    ],
};

const curCompletePersonalInformationStep = ref<number>(1);
const completePersonalInformationFormRef = ref<FormInst | null>(null);
const completePersonalInformationModel = ref<any>({
    gender: "0",
    userName: null,
    birthday: null,
    procedureSelected: [],
});

const isDisabledUserMemberPublish = computed(() => {
    return completePersonalInformationModel.value.userName ||
        completePersonalInformationModel.value.procedureSelected
        ? false
        : true;
});
const completePersonalInformationModelRules: FormRules = {
    gender: [
        {
            required: false,
        },
    ],
    userName: [
        {
            required: false,
        },
    ],
    procedureSelected: [
        {
            required: false,
        },
    ],
};

const onSelectBirthady = (date: { year: any; month: any; day: any }) => {
    const { year, month, day } = date;
    completePersonalInformationModel.value.birthday = `${year}-${month}-${day}`;
};

const _handleClearRegisterEmail = () => {
    registerEmail.value.email = "";
};

const _handleValidEmail = () => {
    if (isDisabledRegisterEmailBtn.value) {
        MessageAlert("Please input correct email");
        return;
    }
    //createAccountStep.value = 2;
    registerEmailFormRef.value?.validate(async (errors) => {
        if (!errors) {
            const params: any = {
                email: registerEmail.value.email,
                userRole: cacheUserRole.value,
            };
            const res: any = await _clickVerifyEmailByApi(params);
            if (res?.code === REQUEST_SCCUESS_CODE) {
                //下一步
                createAccountStep.value = 2;
            } else {
                let errMess = res?.msg || "email verify failed";
                MessageAlert(errMess);
            }
        } else {
            //console.error(errors);
            const errMess =
                errors[0] && errors[0][0]?.message
                    ? errors[0][0]?.message
                    : "Please enter a valid email address";
            MessageAlert(errMess);
        }
    });
};

const _hanleResendEmail = () => {
    _handleValidEmail();
};

const _hanleCheckVerify = async () => {
    const params: any = {
        email: registerEmail.value.email,
        userRole: cacheUserRole.value,
    };
    //createAccountStep.value = 3;
    const res: any = await _isConfirmEmailByApi(params);
    if (res?.code === REQUEST_SCCUESS_CODE) {
        //下一步
        createAccountStep.value = 3;
    } else {
        let errMess = res?.msg || "email confirm failed";
        MessageAlert(errMess);
    }
};

const _handleClearRegisterUserName = () => {
    registerMessageModel.value.nickname = "";
};

const _handleClearRegisterPassword = () => {
    registerMessageModel.value.password = "";
};

const _handleClearRegisterConfirmPassword = () => {
    registerMessageModel.value.confirmPassword = "";
};

const _handleShowRegisterPassword = () => {
    showRegisterPassword.value = !showRegisterPassword.value;
};

const _handleShowRegisterConfirmPassword = () => {
    showRegisterConfirmPassword.value = !showRegisterConfirmPassword.value;
};

const updateCreateAccountStep = (step: number) => {
    emits("update:currentStep", step);
};

const updateCompleteInfoStep = (step: number) => {
    emits("onChangeuserInfoStep", step);
};

const _handleCreateAccount = () => {
    if (isDisabledCreateAccount.value) {
        MessageAlert("Please enter");
        return;
    }
    if (
        registerMessageModel.value.password !==
        registerMessageModel.value.confirmPassword
    ) {
        MessageAlert("confirmPassword must be consistent");
        return;
    }
    //createAccountStep.value = 4;
    registerFormRef.value?.validate(async (errors) => {
        if (!errors) {
            try {
                const userType = storage.get(USER_INDENTIFY_KEY) || 1;
                const params = {
                    email: registerEmail.value.email,
                    nickname: registerMessageModel.value.nickname,
                    password: registerMessageModel.value.password,
                    userRole:
                        Number(userType) === 1
                            ? UserRole.User
                            : UserRole.Doctor,
                };
                await store.dispatch("user/userRegisterEmail", params);
                createAccountStep.value = 4;
            } catch (error: any) {
                const errMess = error?.message || "register failed";
                MessageAlert(errMess);
            }
        } else {
            //console.error(errors);
            const errMess =
                errors[0] && errors[0][0]?.message
                    ? errors[0][0]?.message
                    : "Please enter a valid email address";
            MessageAlert(errMess);
        }
    });
};

watch(
    () => props.currentStep,
    (newV) => {
        createAccountStep.value = newV;
    },
    { immediate: true }
);

watch(
    () => createAccountStep.value,
    (newV) => {
        updateCreateAccountStep(newV);
    },
    { immediate: true }
);

watch(
    () => props.curBaseInfoStep,
    (newV) => {
        curCompletePersonalInformationStep.value = newV;
    },
    { immediate: true }
);

watch(
    () => curCompletePersonalInformationStep.value,
    (newV) => {
        updateCompleteInfoStep(newV);
    },
    { immediate: true }
);

// 注册-选择完职业之后点击next按钮
// const _handleChooseCareerNext = (type: number) => {
//     if (type === 1) {
//         createAccountStep.value = 4;
//     } else if (type === 2) {
//         createAccountStep.value = 6;
//     }
// };

//注册账号-完善个人信息时跳过
const skipCompletePersonalInformation = () => {
    if (curCompletePersonalInformationStep.value === 1) {
        curCompletePersonalInformationStep.value = 2;
    } else {
        createAccountStep.value++;
    }
};

const _handleShowLogin = () => {
    emits("onLogin");
    createAccountStep.value = 1;
};

const _handleRefreshInterestedList = () => {
    _initInterestedPage();
};

const _hancleSelcetProcedureItem = (item: any) => {
    const findIdx =
        completePersonalInformationModel.value.procedureSelected.findIndex(
            (ele: any) => ele === item?.id
        );
    if (findIdx > -1) {
        completePersonalInformationModel.value.procedureSelected.splice(
            findIdx,
            1
        );
    } else {
        completePersonalInformationModel.value.procedureSelected.push(item?.id);
    }
};

const _goToPageDoctorProfile = () => {
    router.push({ path: "/user/profile", query: { from: "user" } });
};

const _handleGoToMyProfilePage = () => {
    _goToPageDoctorProfile();
    nextTick(() => {
        emits("onClose");
    });
};

const _handlePublish = () => {
    //createAccountStep.value = 6;
    completePersonalInformationFormRef.value?.validate(async (errors) => {
        if (!errors) {
            const params = {
                email: registerEmail.value.email,
                nickname: completePersonalInformationModel.value.userName,
                birthday: completePersonalInformationModel.value.birthday,
                gender: completePersonalInformationModel.value.gender,
                interested:
                    completePersonalInformationModel.value.procedureSelected,
            };
            const res: any = await _userSetTheirProfileByApi(params);
            if (res?.code === REQUEST_SCCUESS_CODE) {
                createAccountStep.value = 6;
            } else {
                const errMess = res?.msg || "api failed";
                MessageAlert(errMess);
            }
        } else {
            //console.error(errors);
            const errMess =
                errors[0] && errors[0][0]?.message
                    ? errors[0][0]?.message
                    : "Please check errors";
            MessageAlert(errMess);
        }
    });
};

const _initRegisterPage = () => {
    const cacheUserRoleLocal = store.getters["user/getUserRole"];
    cacheUserRole.value = cacheUserRoleLocal;

    const cacheUserThirdEmail = store.getters["user/getUserThirdEmail"];
    registerEmail.value.email = cacheUserThirdEmail;
};

onMounted(() => {
    nextTick(() => {
        _initRegisterPage();
    });
});
</script>

<style lang="scss" scoped>
.register-message-cont {
    .email-address-input-cont {
        margin-top: 48px;

        .next-btn-cont {
            :deep(.n-button) {
                width: 100%;
                height: 60px;
                font-family: "PingFang SC";
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px;
            }
        }

        .log-in-tip {
            margin-top: 32px;
            color: rgba(0, 0, 0, 0.8);
            text-align: center;
            font-family: "PingFang SC";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            span {
                color: rgba(255, 82, 82, 0.8);
                text-decoration-line: underline;
                cursor: pointer;
            }
        }
    }

    .verify-identity-cont {
        margin-top: 103px;
        text-align: center;
        font-size: 16px;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        .has-sended-message {
            color: rgba(0, 0, 0, 0.2);

            span {
                color: rgba(0, 0, 0, 0.6);
            }
        }

        .btn-group {
            display: flex;
            margin-top: 103px;
            align-items: center;
            justify-content: flex-end;

            .custom-btn {
                margin-left: 12px;
                padding: 12px 28px;
                font-family: "PingFang SC";
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px; /* 100% */
                cursor: pointer;
            }

            .resend-btn {
                color: rgba(255, 82, 82, 0.8);
                background-color: #ffffff;
                text-decoration: underline;
            }

            .check-btn {
                color: #ffffff;
                background-color: #070707;
            }
        }
    }

    .account-message-input-cont {
        margin-top: 12px;

        :deep(.n-input .n-input__suffix) {
            img {
                width: 20px;
                height: 16px;
                cursor: pointer;
            }
        }

        .create-account-btn-cont {
            margin-top: 8px;

            :deep(.n-button) {
                width: 100%;
                height: 60px;
                font-family: "PingFang SC";
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px;
            }
        }
    }

    .choose-career {
        margin-top: 94px;
    }

    .complete-personal-information {
        margin-top: 12px;

        :deep(.n-date-picker) {
            width: 100%;
        }

        :deep(.n-radio) {
            justify-content: center;
            .n-radio__label {
                color: rgba(0, 0, 0, 0.2);
                font-family: "PingFang SC";
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }

        :deep(.n-radio.n-radio--checked) {
            .n-radio__label {
                color: rgba(0, 0, 0, 0.8);
            }
        }

        .personal-preference-procedure {
            .refresh-procedure-tip {
                color: rgba(0, 0, 0, 0.8);
                text-align: center;
                font-family: "PingFang SC";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                span {
                    color: rgba(255, 82, 82, 0.8);
                    text-decoration-line: underline;
                    cursor: pointer;
                }

                .btn-refresh {
                    cursor: pointer;
                }
            }

            .procedure-list-cont {
                width: calc(100% + 12px);
                margin-top: 12px;

                .procedure-list-wrapper {
                    display: flex;
                    width: calc(100% - 12px);
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: flex-start;
                    flex-wrap: wrap;
                    .procedure-list-item {
                        margin-top: 12px;
                        width: 98px;

                        &.selected .img-cont {
                            border: 2px solid rgba(0, 0, 0, 1);
                        }

                        .img-cont {
                            width: 98px;
                            height: 98px;
                            border: 2px solid rgba(0, 0, 0, 0.2);
                            box-sizing: border-box;
                            border-radius: 50%;
                            overflow: hidden;
                            cursor: pointer;

                            img {
                                width: 98px;
                                height: 98px;
                            }
                        }

                        .procedure-text {
                            color: rgba(0, 0, 0, 0.8);
                            text-align: center;
                            font-family: "Open Sans";
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }
                    }
                }
            }
        }

        .btn-cont {
            margin-top: 32px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            :deep(.n-button) {
                width: 232px;
                height: 60px;
                font-family: "PingFang SC";
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px;
            }
        }

        .complete-personal-information-line {
            margin-top: 100px;

            &.two-complete-personal-information {
                margin-top: 32px;

                .line .weight-line {
                    position: relative;
                    left: 50%;
                }
            }

            .line {
                height: 1px;
                background-color: #9fc1ff;

                .weight-line {
                    width: 50%;
                    height: 2px;
                    background-color: rgba(0, 0, 0, 0.6);
                }
            }

            .text {
                margin-top: 24px;
                color: rgba(0, 0, 0, 0.6);
                text-align: center;
                font-family: "Open Sans";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }

    .register-done {
        margin-top: 68px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .title {
            color: #000;
            text-align: center;
            font-family: "Open Sans";
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        .big-logo-cont {
            margin-top: 66px;
            width: 240px;
            height: 256px;
        }

        :deep(.n-button) {
            margin-top: 66px;
            width: 100%;
            height: 60px;
            font-family: "PingFang SC";
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
        }
    }
}
@media screen and (max-width: 1024px) and (min-width: 744px) {
}
@media screen and (max-width: 744px) {
    .register-message-cont {
        .email-address-input-cont {
            .log-in-tip {
                margin-top: 16px;
            }
        }

        .verify-identity-cont {
            .btn-group {
                gap: 12px;

                .custom-btn {
                    margin-left: 0;
                    padding: 12px 20px;
                }
            }
        }

        .choose-career {
            margin-top: 48px;
        }

        .complete-personal-information {
            :deep(.n-radio) {
                .n-radio__label {
                    font-size: 14px;
                }
            }
            .personal-preference-procedure {
                .procedure-list-cont {
                    .procedure-list-wrapper {
                        .procedure-list-item {
                            .procedure-text {
                                display: -webkit-box;
                                max-height: 40px;
                                -webkit-line-clamp: 2;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: normal;
                                -webkit-box-direction: vertical;
                            }
                        }
                    }
                }
            }

            .btn-cont {
                margin-top: 24px;
                gap: 12px;
                :deep(.n-button) {
                    width: unset;
                    flex: 1;
                }
            }

            .complete-personal-information-line {
                margin-top: 24px;

                &.two-complete-personal-information {
                    margin-top: 12px;
                }
            }
        }

        .register-done {
            margin-top: 100px;
            .title {
                font-size: 28px;
            }

            .big-logo-cont {
                margin-top: 48px;
                width: 120px;
                height: 128px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: fill;
                }
            }

            :deep(.n-button) {
                margin-top: 48px;
            }
        }
    }
}
</style>
