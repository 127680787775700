<template>
    <!-- 底部网站信息描述 -->
    <div class="footer">
        <div class="footer-cont">
            <div class="logo-cont">
                <img src="@/assets/images/home/footer_logo.png" />
                <div>Charm</div>
            </div>

            <div class="other-cont">
                <div class="about-us">
                    <div class="title">About us</div>
                    <div class="about-item" @click="_goTOPrecdureIndex">
                        Procedures
                    </div>
                    <div class="about-item" @click="_goToDoctorIndex">
                        Doctors
                    </div>
                </div>

                <div class="contact-us">
                    <div class="title" @click="goContactUs">Contact Us</div>
                    <div class="contact-item">
                        <img src="@/assets/images/home/face_new.png" />
                        <div>9100 Wilshire Blvd, Beverly hills, CA 90212</div>
                    </div>
                    <div class="contact-item">
                        <img src="@/assets/images/home/email_new.png" />
                        <div>marketing@charm-life.com</div>
                    </div>
                </div>

                <div class="follow-us">
                    <div class="title">Follow Us</div>
                    <div class="icon-cont">
                        <img src="@/assets/images/home/facebook_icon_new.png" />
                        <img
                            src="@/assets/images/home/instagram_icon_new.png"
                        />
                        <img src="@/assets/images/home/linkedin_icon_new.png" />
                        <img src="@/assets/images/home/tiktok_icon_new.png" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useRouter } from "vue-router";

const router = useRouter();

const _goTOPrecdureIndex = () => {
    router.push({ path: "/procedure" });
};

const _goToDoctorIndex = () => {
    router.push({ path: "/doctor" });
};

const goContactUs = () => {
    router.push({ path: "/contactUs" });
};
</script>

<style lang="scss" scoped>
.footer {
    padding: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    background-color: #0f0f0f;
    color: #c1c1c1;

    .footer-cont {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .logo-cont {
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            .img {
                width: 142px;
                height: 142px;
            }

            div {
                margin-top: 35.56px;
                color: rgba(255, 255, 255, 0.62);
                font-family: "Oleo Script Swash Caps Regular";
                font-size: 34.551px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }

        .other-cont {
            display: flex;
            flex: 3;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            .about-us {
                flex: 1;
                font-family: "Open Sans";
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;

                .about-item {
                    margin-top: 20px;
                    font-size: 14px;
                    font-weight: 400;
                    cursor: pointer;

                    &:nth-child(3) {
                        margin-top: 12px;
                    }
                }
            }

            .contact-us {
                flex: 1;
                font-family: "Open Sans";
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;

                .title {
                    cursor: pointer;
                }

                .contact-item {
                    margin-top: 20px;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    font-size: 14px;
                    font-weight: 400;

                    &:nth-child(3) {
                        margin-top: 12px;
                    }

                    img {
                        width: 16px;
                        height: 16px;
                    }

                    div {
                        margin-left: 2px;
                    }
                }
            }

            .follow-us {
                flex: 1;
                font-family: "Open Sans";
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;

                .icon-cont {
                    display: flex;
                    margin-top: 20px;
                    height: 40px;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    gap: 10px;

                    img {
                        width: 40px;
                        height: 40px;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1024px) and (min-width: 744px) {
    .footer {
        .footer-cont {
            flex-direction: column;
            .other-cont {
                margin-top: 20px;
                .contact-us {
                    margin-left: 20px;
                }
                .follow-us {
                    margin-left: 20px;
                }
            }
        }
    }
}

@media screen and (max-width: 744px) {
    .footer {
        padding: 20px;
        .footer-cont {
            flex-direction: column;
            .other-cont {
                flex-direction: column;
                margin-top: 20px;
                gap: 20px;
                .about-us {
                    .about-item {
                        padding-left: 10px;
                    }
                }
                .contact-us {
                    margin-left: 0;
                    .contact-item {
                        padding-left: 10px;
                    }
                }
                .follow-us {
                    margin-left: 0;
                    .icon-cont{
                        padding-left: 10px;
                    }
                }
            }
        }
    }
}
</style>
