<template>
    <!-- 登陆信息容器 -->
    <div class="login-main-container">
        <div v-if="curLoginStep === 1" class="select-user-identify">
            <ChoseUserIdentify
                @on-next="_handleLoginSelectIdentifyNext"
            ></ChoseUserIdentify>
        </div>
        <div v-if="curLoginStep === 2" class="login-message-cont">
            <n-form
                ref="loginMessageFormRef"
                :model="loginMessageModel"
                :rules="loginModelRules"
            >
                <n-form-item path="email" label="Email Address">
                    <n-input
                        v-model:value="loginMessageModel.email"
                        placeholder="Enter your Email Address"
                        size="large"
                        @keydown.enter.prevent
                    >
                        <template #suffix>
                            <n-icon
                                v-show="showLoginEmailClearBtn"
                                class="btn-icon"
                                size="16"
                                @click="_handleClearLoginEmail"
                            >
                                <img
                                    src="@/assets/icons/icon_input_clear_plain.svg"
                                />
                            </n-icon>
                        </template>
                    </n-input>
                </n-form-item>

                <n-form-item path="password" label="Password">
                    <n-input
                        v-model:value="loginMessageModel.password"
                        :type="showClearPassword ? 'text' : 'password'"
                        placeholder="Enter Your Password"
                        size="large"
                        @input="handlePasswordInput"
                        @keydown.enter.prevent
                    >
                        <template #suffix>
                            <n-icon
                                class="btn-icon"
                                size="24"
                                @click="_handleShowPassword"
                            >
                                <img
                                    v-if="showClearPassword"
                                    src="@/assets/icons/icon_layout_eye.svg"
                                />
                                <img
                                    v-else
                                    src="@/assets/icons/icon_layout_password.svg"
                                />
                            </n-icon>
                            <n-icon
                                v-show="showLoginPasswordClearBtn"
                                class="btn-icon"
                                size="16"
                                @click="_handleClearLoginPassword"
                            >
                                <img
                                    src="@/assets/icons/icon_input_clear_plain.svg"
                                />
                            </n-icon>
                        </template>
                    </n-input>
                </n-form-item>
            </n-form>

            <div class="remember-and-forget-password-cont">
                <n-checkbox value="rememberAccount">Remember me</n-checkbox>
                <div @click="_handleForgotPassword">Forgot your password?</div>
            </div>

            <div class="login-btn-cont">
                <n-button
                    :color="
                        !isDisabledLoginBtn ? '#000000' : 'rgba(0, 0, 0, 0.2)'
                    "
                    :text-color="!isDisabledLoginBtn ? '#88EEFF' : '#ffffff'"
                    :disabled="isDisabledLoginBtn"
                    @click="loginCharm"
                >
                    Log in
                </n-button>
            </div>

            <div class="line-or-line">
                <div class="line"></div>
                <div class="text">OR</div>
                <div class="line"></div>
            </div>

            <div class="other-login-mode">
                <GoogleLogin
                    popup-type="TOKEN"
                    :buttonConfig="{
                        type: 'icon',
                        size: 'large',
                        shape: 'circle',
                    }"
                    :callback="_onGoogleLoginCallbackToken"
                    :error="onGoogleLoginError"
                >
                    <div class="other-login-sec login-btn">
                        <img src="@/assets/icons/ic_google_search.svg" />
                        <span>Sign in with Google</span>
                    </div>
                </GoogleLogin>
                <!-- <GoogleLogin
                    :callback="callbackUser"
                    :buttonConfig="{
                        type: 'standard',
                        size: 'large',
                        theme: 'filled_blue',
                        width: 320,
                    }"
                >
                </GoogleLogin> -->
                <!-- <div
                    class="other-login-sec"
                    @click="_handleSimpleLogin(SimpleLoginType.Google)"
                >
                    <img src="@/assets/images/layout/google.png" />
                </div> -->
                <!-- <div
                    class="other-login-sec"
                    @click="_handleSimpleLogin(SimpleLoginType.Apple)"
                >
                    <img src="@/assets/images/layout/apple.png" />
                </div>
                <div
                    class="other-login-sec"
                    @click="_handleSimpleLogin(SimpleLoginType.Facebook)"
                >
                    <img src="@/assets/images/layout/facebook.png" />
                </div> -->
            </div>

            <div class="create-account-tip">
                Don’t have an account?
                <span @click="_handleRegister">Create one!</span>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, computed, watch, nextTick } from "vue";
import { useStore } from "vuex";
import {
    NForm,
    NFormItem,
    NInput,
    NCheckbox,
    NButton,
    NIcon,
    FormInst,
    FormRules,
    FormItemRule,
} from "naive-ui";
import { REQUEST_SCCUESS_CODE, USER_INDENTIFY_KEY } from "@/constant";
import { UserRole, SimpleLoginType } from "@/constant";
import storage from "@/utils/storage";
import ChoseUserIdentify from "./ChoseUserIdentify.vue";
import { MessageAlert } from "@/composables/useMessageAlert";
import { validateEmail, validateLoginPassword } from "@/utils/util";
import { loginWithSocial } from "@/utils/third-login";
import { jwtDecode } from "jwt-decode";
import {
    googleLogout,
    googleTokenLogin,
    decodeCredential,
} from "vue3-google-login";
import type { CallbackTypes } from "vue3-google-login";

const props = defineProps({
    currentStep: {
        type: Number,
        default: () => {
            return 1;
        },
    },
});
const emits = defineEmits([
    "update:currentStep",
    "onLoginIn",
    "onRegister",
    "onForgotPassword",
    "onClose",
]);
const store = useStore();
const showClearPassword = ref<boolean>(false);
const loginMessageFormRef = ref<FormInst | null>(null);
const curLoginStep = ref<number>(1);
const loginMessageModel = ref<any>({
    email: null,
    password: null,
});
const loginModelRules: FormRules = {
    email: [
        {
            required: true,
            trigger: ["input"],
            validator: (rule: FormItemRule, value: any) => {
                //console.log(rule, value);
                if (!value) {
                    return new Error("Please input");
                } else if (!validateEmail(value)) {
                    return new Error("Please enter a valid email address");
                }
                return true;
            },
        },
    ],
    password: [
        {
            required: true,
            trigger: ["input"],
            validator: (rule: FormItemRule, value: any) => {
                //console.log(rule, value);
                if (!value) {
                    return new Error("Please input");
                } else if (!validateLoginPassword(value)) {
                    return new Error("password must 6~16 letters and numbers");
                }
                return true;
            },
        },
    ],
};

const isDisabledLoginBtn = computed(() => {
    return loginMessageModel.value.email && loginMessageModel.value.password
        ? false
        : true;
});

const showLoginEmailClearBtn = computed(() => {
    return loginMessageModel.value.email ? true : false;
});

const showLoginPasswordClearBtn = computed(() => {
    return loginMessageModel.value.password ? true : false;
});

const isLogined = computed(() => {
    return store.getters["user/getLoginStatus"];
});

const updateLoginStep = (step: number) => {
    emits("update:currentStep", step);
};

watch(
    () => props.currentStep,
    (newV) => {
        curLoginStep.value = newV;
    },
    { immediate: true }
);

watch(
    () => curLoginStep.value,
    (newV) => {
        updateLoginStep(newV);
    },
    { immediate: true }
);

const _handleClearLoginEmail = () => {
    loginMessageModel.value.email = "";
};

const _handleClearLoginPassword = () => {
    loginMessageModel.value.password = "";
};

// 登陆输入密码监听
const handlePasswordInput = () => {
    console.log("密码输入变化");
};

const _handleRegister = (type?: string) => {
    emits("onRegister", type);
};

const _handleForgotPassword = () => {
    emits("onForgotPassword");
};

const _onGoogleLoginCallbackUser: CallbackTypes.CredentialCallback = async (
    response: any
) => {
    // This callback will be triggered when the user selects or login to his Google account from the popup
    console.log("Credential JWT string", response);
    const userData = decodeCredential(response.credential);
    console.log("Handle Google the userData", userData);
    const params = {
        email: userData?.email,
        provider: "google",
    };
    await store.dispatch("user/userLoginIn", params);
};

const _goToRegister = (type?: string) => {
    _handleRegister(type);
};

const _onGoogleLoginCallbackToken: CallbackTypes.TokenResponseCallback = async (
    response: any
) => {
    //console.log("Access token", response);
    const accessToken = response?.access_token;
    // console.log("Handle Google the accessToken", accessToken);
    const userType = storage.get(USER_INDENTIFY_KEY) || 1;
    const params = {
        googleAccessToken: accessToken,
        provider: "google",
        userRole: Number(userType) === 1 ? UserRole.User : UserRole.Doctor,
    };
    const res = await store.dispatch("user/userLoginIn", params);
    //console.log("Access res", res);
    if (res.code === REQUEST_SCCUESS_CODE) {
        _goToRegister("thirdlogin");
    }
};

const onGoogleLoginError = (error: any) => {
    MessageAlert(
        error?.error?.message ||
            "Sorry, Google login failed, please login use by email accocunt"
    );
};

const _loginUserByApi = async () => {
    const userType = storage.get(USER_INDENTIFY_KEY) || 1;
    const params = {
        ...loginMessageModel.value,
        provider: "email",
        userRole: Number(userType) === 1 ? UserRole.User : UserRole.Doctor,
    };
    try {
        await store.dispatch("user/userLoginIn", params);
        await store.dispatch("user/fetchUserInfo");
        if (isLogined.value) {
            _handleCloseLoginModel();
        }
    } catch (error: any) {
        const errMess = error?.message || "login failed";
        MessageAlert(errMess);
    }
};

const _handleCloseLoginModel = () => {
    nextTick(() => {
        emits("onClose");
    });
};

// 登陆
const loginCharm = () => {
    loginMessageFormRef.value?.validate((errors) => {
        if (!errors) {
            _loginUserByApi();
        } else {
            //console.error(errors);
            const errMess =
                errors[0] && errors[0][0]?.message
                    ? errors[0][0]?.message
                    : "Please enter a valid email address";
            MessageAlert(errMess);
        }
    });
};

const _handleShowPassword = () => {
    showClearPassword.value = !showClearPassword.value;
};

const _handleSimpleLogin = async (type: SimpleLoginType) => {
    try {
        //const res = await loginWithSocial(type);
        if (type === SimpleLoginType.Google) {
            googleTokenLogin().then((response) => {
                console.log("Handle the response", response);
            });
            // const resData: any = jwtDecode(res.credential);
            // console.log("Google====>", resData);
            // const params = {
            //     googleAccessToken: resData.googleAccessToken,
            //     provider: "google",
            // };
            // await store.dispatch("user/userLoginSocial", params);
        } else if (type === SimpleLoginType.Apple) {
            // const resData: any = jwtDecode(res.authorization.id_token);
            // console.log("Apple====>", resData);
            // const params = {
            //     appleIdToken: resData.appleIdToken,
            //     provider: "google",
            // };
            // await store.dispatch("user/userLoginSocial", params);
        } else if (type === SimpleLoginType.Facebook) {
            (window as any).FB.api("/me?fields=email,name", (resp: any) => {
                const resData = resp;
                console.log("Facebook====>", resData);
                // const params = {
                //     appleIdToken: resData.appleIdToken,
                //     provider: "google",
                // };
                // await store.dispatch("user/userLoginSocial", params);
            });
        } else {
            console.error("No type for login, please check");
        }
    } catch (error) {
        console.error(`Login error with ${type}:`, error);
    }
};

const _handleLoginSelectIdentifyNext = (type: string) => {
    //缓存type身份
    store.dispatch("user/setAccountTypeAct", type);

    //进入登录页面
    curLoginStep.value = 2;
};
</script>

<style lang="scss" scoped>
.login-main-container {
    .select-user-identify {
        margin-top: 94px;
    }
    .login-message-cont {
        margin-top: 12px;

        :deep(.n-input .n-input__suffix) {
            .btn-icon {
                margin-left: 12px;
            }
            img {
                width: 20px;
                height: 16px;
                cursor: pointer;
            }
        }

        .remember-and-forget-password-cont {
            margin-top: 12px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            div {
                color: rgba(0, 0, 0, 0.6);
                font-family: "Open Sans";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                cursor: pointer;
            }
        }

        .login-btn-cont {
            margin-top: 18px;

            :deep(.n-button) {
                width: 100%;
                height: 60px;
                font-family: "PingFang SC";
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px;
            }
        }

        .line-or-line {
            margin-top: 32px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .line {
                width: 210px;
                height: 1px;
                background-color: rgba(187, 184, 164, 0.4);
            }

            .text {
                color: rgba(0, 0, 0, 0.2);
                text-align: center;
                font-family: "PingFang SC";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }

        .other-login-mode {
            margin-top: 16px;
            padding: 0;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            :deep(.g-btn-wrapper) {
                width: 100%;
            }

            .other-login-sec {
                cursor: pointer;
                img {
                    width: 60px;
                    height: 60px;
                }
                .g_id_signin {
                    width: 60px;
                    height: 60px;
                }
            }

            .login-btn {
                display: flex;
                padding: 0 16px;
                align-items: center;
                justify-content: center;
                border-radius: 12px;
                border: 1px solid #e2e2e2;
                transition: 0.2s all;
                cursor: pointer;

                img {
                    width: 48px;
                    height: 48px;
                }

                > span {
                    padding-left: 12px;
                    font-family: Roboto-Regular;
                    font-weight: 400;
                    font-size: 14px;
                    color: #424242;
                    font-style: normal;
                    transition: 0.2s all;
                }
            }
            .login-btn:hover {
                border-color: #5789f0;
                background-color: #f2f7ff;
            }
        }

        .create-account-tip {
            margin-top: 32px;
            color: rgba(0, 0, 0, 0.8);
            text-align: center;
            font-family: "PingFang SC";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            span {
                color: rgba(255, 82, 82, 0.8);
                text-decoration-line: underline;
                cursor: pointer;
            }
        }
    }
}
@media screen and (max-width: 1024px) and (min-width: 744px) {
}
@media screen and (max-width: 744px) {
    .login-main-container {
        .login-message-cont {
            .remember-and-forget-password-cont {
                div {
                    font-size: 12px;
                }
            }

            .login-btn-cont {
                margin-top: 12px;
            }

            .line-or-line {
                margin-top: 12px;
                .line {
                    width: 100%;
                }

                .text {
                    font-size: 12px;
                }
            }

            .other-login-mode {
                justify-content: center;
                margin-top: 12px;
                padding: 0 20px;
                gap: 20px;

                .other-login-sec {
                    img {
                        width: 50px;
                        height: 50px;
                    }
                }
            }

            .create-account-tip {
                margin-top: 12px;
                font-size: 12px;
            }
        }
    }
}
</style>
