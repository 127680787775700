import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/home/footer_logo.png'
import _imports_1 from '@/assets/images/home/face_new.png'
import _imports_2 from '@/assets/images/home/email_new.png'
import _imports_3 from '@/assets/images/home/facebook_icon_new.png'
import _imports_4 from '@/assets/images/home/instagram_icon_new.png'
import _imports_5 from '@/assets/images/home/linkedin_icon_new.png'
import _imports_6 from '@/assets/images/home/tiktok_icon_new.png'


const _hoisted_1 = { class: "footer" }

import { useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'FooterBar',
  setup(__props) {

const router = useRouter();

const _goTOPrecdureIndex = () => {
    router.push({ path: "/procedure" });
};

const _goToDoctorIndex = () => {
    router.push({ path: "/doctor" });
};

const goContactUs = () => {
    router.push({ path: "/contactUs" });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", { class: "footer-cont" }, [
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "logo-cont" }, [
        _createElementVNode("img", { src: _imports_0 }),
        _createElementVNode("div", null, "Charm")
      ], -1)),
      _createElementVNode("div", { class: "other-cont" }, [
        _createElementVNode("div", { class: "about-us" }, [
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "title" }, "About us", -1)),
          _createElementVNode("div", {
            class: "about-item",
            onClick: _goTOPrecdureIndex
          }, " Procedures "),
          _createElementVNode("div", {
            class: "about-item",
            onClick: _goToDoctorIndex
          }, " Doctors ")
        ]),
        _createElementVNode("div", { class: "contact-us" }, [
          _createElementVNode("div", {
            class: "title",
            onClick: goContactUs
          }, "Contact Us"),
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "contact-item" }, [
            _createElementVNode("img", { src: _imports_1 }),
            _createElementVNode("div", null, "9100 Wilshire Blvd, Beverly hills, CA 90212")
          ], -1)),
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "contact-item" }, [
            _createElementVNode("img", { src: _imports_2 }),
            _createElementVNode("div", null, "marketing@charm-life.com")
          ], -1))
        ]),
        _cache[3] || (_cache[3] = _createStaticVNode("<div class=\"follow-us\" data-v-235cd74a><div class=\"title\" data-v-235cd74a>Follow Us</div><div class=\"icon-cont\" data-v-235cd74a><img src=\"" + _imports_3 + "\" data-v-235cd74a><img src=\"" + _imports_4 + "\" data-v-235cd74a><img src=\"" + _imports_5 + "\" data-v-235cd74a><img src=\"" + _imports_6 + "\" data-v-235cd74a></div></div>", 1))
      ])
    ])
  ]))
}
}

})